import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UIModule } from '@bannerflow/ui';
import { BehaviorSubject, map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { FeedField, FeedFieldType } from 'src/app/models/feed.model';
import { Template } from 'src/app/models/templates.model';
import { MainFeedFieldsService } from 'src/app/services/api/main-feed-fields.service';
import { PreviewService } from 'src/app/services/api/preview.service';
import { TemplateService } from 'src/app/services/api/template.service';
import { GenericFeedFieldFormGroup, TemplateBuilderService } from 'src/app/services/ui/template-builder.service';
import { AddFieldComponent } from 'src/app/shared/add-field/add-field.component';
import { EditableFieldComponent } from 'src/app/shared/editable-field/editable-field.component';
import { PreviewValueComponent } from 'src/app/shared/preview-value/preview-value.component';
import { mapFormGroupToFeedFields } from 'src/app/utils/main-feed-helper';
import { v4 as uuidv4 } from 'uuid';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';

@Component({
    selector: 'template-fields',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        UIModule,
        DynamicFormComponent,
        EditableFieldComponent,
        AddFieldComponent,
        PreviewValueComponent
    ],
    templateUrl: './template-fields.component.html',
    styleUrl: './template-fields.component.scss'
})
export class TemplateFieldsComponent {
    @Input() formGroup: GenericFeedFieldFormGroup;
    @Input() template: Template;
    @Input() feedSourceUrl: string;
    @Output() updateTemplateLabel = new EventEmitter<Template>();

    previewService = inject(PreviewService);
    templateService = inject(TemplateService);
    templateBuilder = inject(TemplateBuilderService);
    private readonly mainFeedFieldsService = inject(MainFeedFieldsService);

    formRawValueChanges$: Observable<FeedField[]>;
    currentPage$ = new BehaviorSubject<number>(1);
    previewData$: Observable<{ data: any[]; total: number }>;

    ngOnInit() {
        this.formRawValueChanges$ = this.formGroup.valueChanges.pipe(
            map(_ => mapFormGroupToFeedFields(this.formGroup.controls.fields)),
            startWith(mapFormGroupToFeedFields(this.formGroup.controls.fields)),
            tap(feedFields => {
                // TODO WORKAROUND: This is a workaround to update the creativeId and creativeSetId in the main feed fields
                const mainFields = this.mainFeedFieldsService._fields$.getValue();
                // we need to find the creative fields in feedFields and update the creativeId and creativeSetId from mainFields if they exist
                const result = feedFields.map(field => {
                    if (field.$type === FeedFieldType.Creative) {
                        const creativeFields = mainFields.filter(
                            mainField => mainField.$type === FeedFieldType.Creative
                        );
                        const creativeField = creativeFields.find(creativeField => creativeField.name === field.name);

                        return {
                            $type: field.$type,
                            id: field.id,
                            required: field.required,
                            name: field.name,
                            mappings: creativeField?.mappings || [],
                            creativeId: creativeField?.creativeId || '',
                            creativeSetId: creativeField?.creativeSetId || ''
                        };
                    } else {
                        return field;
                    }
                });
                this.mainFeedFieldsService.setFields(result);
            })
        );

        this.previewData$ = this.formRawValueChanges$.pipe(
            map(fields => {
                //Filter fields to remove those with an empty path
                const filteredFields = fields.filter((field): field is FeedField => {
                    return (
                        (field.$type === FeedFieldType.Path ? field.path !== '' : true) &&
                        field.$type !== FeedFieldType.Creative
                    );
                });
                return filteredFields;
            }),
            switchMap(filteredFields => {
                // Only proceed if there are any non-empty fields
                if (filteredFields.length > 0) {
                    return this.previewService.getPreviewData({
                        source: this.feedSourceUrl,
                        fields: filteredFields,
                        filters: []
                    });
                } else {
                    // Return an observable that emits nothing
                    return of({ data: [], total: 0 });
                }
            }),
            startWith({ data: [], total: 0 })
        );
    }

    previewPrevious(currentPage: number): void {
        currentPage = currentPage - 1;
        this.currentPage$.next(currentPage);
    }

    previewNext(currentPage: number): void {
        currentPage = currentPage + 1;
        this.currentPage$.next(currentPage);
    }

    onUpdateTemplateLabel(label: string, template: Template) {
        const updatedTemplate = {
            ...template,
            label: label
        };
        this.updateTemplateLabel.emit(updatedTemplate);
    }

    createNewField(newSDAField: string, formGroup: GenericFeedFieldFormGroup): void {
        const newField: FeedField = {
            $type: FeedFieldType.Path,
            name: newSDAField ? newSDAField : 'New field',
            required: false,
            id: uuidv4(),
            path: ''
        };
        formGroup.controls.fields.push(this.templateBuilder.buildFieldGroup(newField));
    }
}
