import { NewRelicOptions } from '@bannerflow/sentinel';
import { environment } from 'src/environments/environment';

export class NewRelicPlugin {
    public static init(isProduction: boolean, origins: string[]): NewRelicOptions {
        const newRelicConfig = {
            applicationId: isProduction ? '538653904' : '538653894',
            accountId: isProduction ? '4122654' : '4232543',
            agentId: isProduction ? '538653904' : '538653894',
            trustKey: '4122654',
            origins: origins,
            licenseKey: isProduction ? 'NRJS-bd28b6acdc31b77b97c' : 'NRJS-d0b27a9b958bc4b281c',
            applicationVersion: environment.buildVersion,
            releaseName: environment.buildVersion,
            releaseId: environment.buildVersion
        };

        (window as any).newRelicConfig = newRelicConfig;
        return newRelicConfig;
    }
}
