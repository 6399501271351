import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { FeedValueObject } from 'src/app/models/feed.model';
import { PreviewValueComponent } from 'src/app/shared/preview-value/preview-value.component';

interface PreviewCarouselData {
    values: { fieldName: string; data: string[] }[];
}

interface PageData {
    currentPage: number;
    activeCard: { [key: string]: FeedValueObject };
    totalCards: number;
}

@Component({
    selector: 'preview-carousel',
    standalone: true,
    imports: [CommonModule, UIModule, PreviewValueComponent],
    templateUrl: './preview-carousel.component.html',
    styleUrls: ['./preview-carousel.component.scss']
})
export class PreviewCarouselComponent implements OnInit {
    @Input() previewData: { [key: string]: FeedValueObject }[];

    previewCarouselData$: Observable<PreviewCarouselData[]>;
    currentPage$ = new BehaviorSubject<number>(1);

    pageInfo$: Observable<PageData>;

    constructor() {}
    ngOnInit(): void {
        if (this.previewData) {
            this.pageInfo$ = this.currentPage$.pipe(
                map(currentPage => {
                    const activeCard = this.previewData[currentPage - 1];
                    return { currentPage, activeCard, totalCards: this.previewData.length };
                })
            );
        }
    }

    returnZero() {
        return 0;
    }

    previous(page: number): void {
        this.currentPage$.next(page - 1);
    }

    next(page: number): void {
        this.currentPage$.next(page + 1);
    }
}
