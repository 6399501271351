import { animate, state, style, transition, trigger } from '@angular/animations';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import { combineLatest, finalize, map, Observable, of, switchMap, tap } from 'rxjs';
import { AccountAccessService } from 'src/app/services/api/account-access.service';
import { CatalogService } from 'src/app/services/api/catalog.service';
import { MainFeedFieldsService } from 'src/app/services/api/main-feed-fields.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { MainFilterService } from 'src/app/services/api/main-filter.service';
import { NavigationService } from 'src/app/services/api/navigation.service';
import { TemplateService } from 'src/app/services/api/template.service';
import { DraggableBottomSheetService } from 'src/app/services/ui/draggable-bottom-sheet.service';
import { StepperService } from 'src/app/services/ui/stepper.service';
import { BottomNavigationComponent } from 'src/app/shared/bottom-navigation/bottom-navigation.component';
import { FiltersComponent } from '../../../shared/filters/filters.component';
import { BottomSheetPreviewComponent } from './bottom-sheet-preview/bottom-sheet-preview.component';

@Component({
    selector: 'customise-feed',
    standalone: true,
    imports: [
        CommonModule,
        FiltersComponent,
        UIModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        ClipboardModule,
        MatButtonModule,
        BottomNavigationComponent
    ],
    templateUrl: './customise-feed.component.html',
    styleUrls: ['./customise-feed.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state(
                'void',
                style({
                    opacity: 0
                })
            ),
            transition('void <=> *', animate('500ms'))
        ])
    ]
})
export class CustomiseFeedComponent implements OnInit, OnDestroy {
    mainFilterService = inject(MainFilterService);
    stepperService = inject(StepperService);
    mainFeedService = inject(MainFeedService);
    templateService = inject(TemplateService);
    mainFeedFieldsService = inject(MainFeedFieldsService);
    uiNotificationService = inject(UINotificationService);
    accountAccessService = inject(AccountAccessService);
    catalogService = inject(CatalogService);
    draggableBottomSheetService = inject(DraggableBottomSheetService);
    navigationService = inject(NavigationService);
    filterGroups$ = this.mainFilterService.groupFilters$;
    isFinished = false;
    isLoading = false;
    isSocial$ = this.templateService.selectedTemplate$.pipe(map(template => template.type !== 'DisplayTemplate'));
    bfcListUrl$: Observable<string> = this.navigationService.getBFCListUrl();
    generatedXML$: Observable<string> = combineLatest([
        this.templateService.selectedTemplate$,
        this.mainFeedService.mainFeedId$,
        this.mainFeedFieldsService.fields$
    ]).pipe(
        switchMap(([template, id, fields]) =>
            this.templateService.getTemplateVerticalById(template.id).pipe(
                switchMap(template => {
                    const bluePrintFields = fields.map(field => ({ id: field.id, name: field.name }));
                    return this.catalogService.createCatalogXML({
                        feedId: id,
                        templateType: template.type,
                        blueprintFields: bluePrintFields
                    });
                }),
                map(response => response.catalogUrl)
            )
        )
    );

    ngOnInit(): void {
        const componentPortal = new ComponentPortal(BottomSheetPreviewComponent);
        this.draggableBottomSheetService.createBottomSheet(componentPortal);
    }

    ngOnDestroy(): void {
        this.draggableBottomSheetService.removeBottomSheet();
    }

    previousStep(): void {
        this.stepperService.previousStep();
    }

    save(): void {
        combineLatest([this.mainFeedService.mainFeedId$, this.mainFilterService.groupFilters$]) // Combine the observables
            .pipe(
                tap(() => (this.isLoading = true)),
                switchMap(([id, groupFilters]) => {
                    if (groupFilters.filterGroups.length === 0) {
                        // If no groupFilters, finish immediately, return an observable that completes
                        return of(null);
                    }
                    return this.mainFilterService.saveMainFilter(id, groupFilters); // Proceed with saving if groupFilters exist
                }),
                finalize(() => {
                    this.isLoading = false;
                }) // Ensure loading is set to false after operation
            )
            .subscribe(_ => {
                this.uiNotificationService.open('Saved successfully', { type: 'info', autoCloseDelay: 3000 });
                this.isFinished = true;
                this.draggableBottomSheetService.removeBottomSheet();
            });
    }

    navigateToBFCList(url: string): void {
      window.location.href = url;
  }
}
