import { Component, input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { PathFieldComponent } from 'src/app/shared/path-field/path-field.component';
import { StaticTextFieldComponent } from 'src/app/shared/static-text-field/static-text-field.component';
import { SelectTypeComponent } from '../../../views/stepper/select-fields/select-type/select-type.component';
import { FeedFieldType } from 'src/app/models/feed.model';
import { MatIcon } from '@angular/material/icon';
import { KeyValuePipe } from '@angular/common';
import { CreativeFieldMapping } from 'src/app/models/feed-form.model';

@Component({
    selector: 'creative-mappings-form',
    standalone: true,
    imports: [PathFieldComponent, StaticTextFieldComponent, SelectTypeComponent, MatIcon, KeyValuePipe],
    templateUrl: './creative-mappings-form.component.html',
    styleUrl: './creative-mappings-form.component.scss'
})
export class CreativeMappingsFormComponent {
    creativeMappingsForm = input.required<FormArray<CreativeFieldMapping>>();

    FeedFieldType = FeedFieldType;
}
