<mat-form-field
    appearance="outline"
    class="tw-w-full tw-h-14 tw-text-sm">
    <mat-label>{{ name() }}</mat-label>
    <input
        type="text"
        [formControl]="textFormControl()"
        placeholder="Static Text"
        matInput />
</mat-form-field>
