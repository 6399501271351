@for (control of formArray.controls; track control; let i = $index) {
    <div
        class="tw-w-full tw-grid tw-auto-rows-auto tw-gap-y-0 tw-py-3"
        [ngClass]="headerTemplateRef ? 'tw-grid-cols-3' : 'tw-grid-cols-2'"
        [class.divider]="i !== (formArray.controls | keyvalue).length - 1">
        <div class="field-label">
            <div class="tw-flex tw-items-center">
                @if (control.controls.removeable.value) {
                    <ui-svg-icon
                        icon="kebab"
                        class="kebab-menu"
                        [uiDropdownTarget]="optionsDropdown" />
                    <div class="tw-flex tw-justify-center tw-items-center tw-gap-3">
                        <editable-field [formCtrl]="control.controls.name">
                            <p>{{ control.controls.name.value }}</p>
                        </editable-field>
                        @if (control.controls.dynamicContentType?.value) {
                            @if (control.controls.dynamicContentType?.value === 'Image') {
                                <ui-svg-icon
                                    icon="image"
                                    class="icon" />
                            } @else {
                                <ui-svg-icon
                                    icon="text"
                                    class="icon" />
                            }
                        }
                    </div>
                } @else {
                    <p>{{ control.controls.name.value }}</p>
                }
            </div>
            <mat-icon
                class="field-icon"
                [class.active]="control.controls.data.value.length > 0 || control.controls.type.value === 'Creative'"
                >link</mat-icon
            >
        </div>
        <div class="tw-flex tw-items-center tw-gap-2">
            <select-type
                class="tw-w-34"
                [fieldFormControlType]="control.controls.type"
                [fieldFormControlData]="control.controls.data"
                [isDynamicFieldContent]="!!control.controls.dynamicContentType?.value" />
            <field-select
                class="tw-flex tw-items-center tw-w-full"
                [fieldFormControl]="control.controls.data"
                [label]="control.controls.name.value"
                [id]="control.controls.id.value"
                [fieldType]="control.controls.type.value"
                (chipClicked)="fieldEditorDialog.open()" />
        </div>
        @if (headerTemplateRef) {
            <div class="tw-flex tw-justify-center tw-items-center">
                <ng-container
                    [ngTemplateOutlet]="headerTemplateRef"
                    [ngTemplateOutletContext]="{ $implicit: control.controls.name.value }">
                </ng-container>
            </div>
        }
    </div>
    <ui-dialog
        #fieldEditorDialog="ui-dialog"
        [config]="{ headerText: 'EDIT ' + control.controls.name.value, maxWidth: 500, maxHeight: 500, padding: 0 }">
        <ng-template ui-dialog-template>
            <field-editor
                (closeDialog)="fieldEditorDialog.close()"
                [formFieldControl]="control">
            </field-editor>
        </ng-template>
    </ui-dialog>
    <ui-dropdown
        theme="default"
        [header]=""
        #optionsDropdown>
        <ui-dropdown-item
            *ngFor="let option of kebabMenuOptions"
            (itemClicked)="onKebabMenuOptionClicked(option, i)">
            {{ option }}
        </ui-dropdown-item>
    </ui-dropdown>
}
