import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { debounceTime, map, Observable, take } from 'rxjs';
import { FeedFieldType } from '../models/feed.model';
import { PreviewService } from '../services/api/preview.service';

export class ValidateFieldExpression {
    static createValidator(previewService: PreviewService): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            const input = control.value;

            // TODO can change from any when children [] is removed from fearsome processor
            const requestField: any = {
                path: [input].toString(),
                id: '',
                name: '',
                required: false,
                $type: FeedFieldType.Path,
                children: []
            };

            return previewService.validateFieldExpression([requestField]).pipe(
                take(1),
                debounceTime(2000),
                map(valid => {
                    if (valid) return null;

                    return { invalidSyntax: true };
                })
            );
        };
    }
}
