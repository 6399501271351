import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UIButtonGroupOption, UIModule } from '@bannerflow/ui';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay } from 'rxjs';
import { Template } from 'src/app/models/templates.model';
import { AccountAccessService } from 'src/app/services/api/account-access.service';
import { TemplateService } from 'src/app/services/api/template.service';
import { StepperService } from 'src/app/services/ui/stepper.service';
import { BottomNavigationComponent } from 'src/app/shared/bottom-navigation/bottom-navigation.component';

type TemplatesSelection = { templates: Template[]; selectedTemplate: Template; tabOption: TabOption };

type TabOption = 'Display' | 'Social';

@Component({
    selector: 'templates',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, UIModule, BottomNavigationComponent],
    templateUrl: './templates.component.html',
    styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent {
    private templateService = inject(TemplateService);
    private stepperService = inject(StepperService);
    private accountAccessService = inject(AccountAccessService);

    private isSocialDynamicAd$: Observable<boolean> = this.accountAccessService.user$.pipe(
        map(user => {
            const userFeatures = user.account.features;
            return userFeatures.includes('SocialDynamicAdvertising');
        })
    );
    selectedTemplateAndTabOption$ = new BehaviorSubject<{ template: Template | undefined; tabOption: TabOption }>({
        template: undefined,
        tabOption: 'Social'
    });
    templates$: Observable<TemplatesSelection> = this.initializeTemplates();
    tabOptions$: Observable<UIButtonGroupOption[]> = this.generateTabOptions();

    selectTemplate(template: Template, tabOption: TabOption): void {
        this.selectedTemplateAndTabOption$.next({ template, tabOption });
    }

    useThisTemplateWithAI(template: Template): void {
        this.handleTemplateSelection(template, true);
    }

    useThisTemplate(template: Template): void {
        this.handleTemplateSelection(template, false);
    }

    updateTabOption(tabOption: TabOption): void {
        this.selectedTemplateAndTabOption$.next({ template: undefined, tabOption });
    }

    editSourceFeed(): void {
        this.stepperService.previousStep();
    }

    private initializeTemplates(): Observable<TemplatesSelection> {
        return combineLatest([
            this.templateService.getTemplates().pipe(shareReplay(1)),
            this.selectedTemplateAndTabOption$,
            this.isSocialDynamicAd$
        ]).pipe(
            map(([templates, selectedTemplate, isSocialDynamicAd]) => {
                const filteredTemplates = templates.filter((template: Template) =>
                    this.isTemplateRelevant(template, selectedTemplate.tabOption, isSocialDynamicAd)
                );

                return {
                    templates: filteredTemplates,
                    selectedTemplate: selectedTemplate.template ?? filteredTemplates[0],
                    tabOption: selectedTemplate.tabOption
                };
            })
        );
    }

    private handleTemplateSelection(template: Template, useAI: boolean): void {
        this.templateService.selectTemplate({ ...template, useAI });
        this.stepperService.completeStep();
    }

    private isTemplateRelevant(template: Template, tabOption: TabOption, isSocialDynamicAd: boolean): boolean {
        switch (tabOption) {
            case 'Display':
                return template.type === 'DisplayTemplate';
            case 'Social':
                return template.type !== 'DisplayTemplate';
            default:
                return false;
        }
    }

    private generateTabOptions(): Observable<UIButtonGroupOption[]> {
        return this.isSocialDynamicAd$.pipe(
            map(isSocialDynamicAd => {
                const createtabOption = (
                    id: string,
                    text: string,
                    value: string,
                    disabled: boolean = false
                ): UIButtonGroupOption => ({
                    id,
                    svgIcon: 'root-folder',
                    text,
                    value,
                    disabled
                });

                return [
                    // Will be re-enabled in a future release
                    // createtabOption('1', 'DISPLAY BLUEPRINTS', 'Display', isSocialDynamicAd),
                    createtabOption('2', 'SOCIAL BLUEPRINTS', 'Social', !isSocialDynamicAd)
                ];
            })
        );
    }
}
