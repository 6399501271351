import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { Business } from 'src/app/models/social-integration.model';
import { environment } from 'src/environments/environment';
import { PublishCatalogMetaRequest, PublishCatalogRequest } from '../../models/catalog.model';

type CatalogResponse = { catalogUrl: string };
export interface MetaCatalogResponse {
    externalId: string,
    name: string;
    feedId: string;
}

@Injectable({
    providedIn: 'root'
})
export class CatalogService {   
    http = inject(HttpClient);

    createCatalogXML(catalogRequest: PublishCatalogRequest): Observable<CatalogResponse> {
        return this.http.post<CatalogResponse>(
            `${environment.origins.feedsSocializingMaster}/api/Meta/catalog/publishxml`,
            catalogRequest
        );
    }

    getBusinessesByIntegrationId(integrationId: string): Observable<Business[]> {
        return this.http.get<Business[]>(
            `${environment.origins.feedsSocializingMaster}/api/Meta/business/${integrationId}`
        );
    }

    createCatalogMeta(catalogMetaRequest: PublishCatalogMetaRequest): Observable<string> {
        return this.http.post(
            `${environment.origins.feedsSocializingMaster}/api/Meta/catalog/publishapi`,
            catalogMetaRequest,
            {
                responseType: 'text'
            }
        );
    }

    getCatalog(feedId: string): Observable<MetaCatalogResponse | null> {
        return this.http.get<MetaCatalogResponse>(
            `${environment.origins.feedsSocializingMaster}/api/Meta/catalog/published?feedId=${feedId}`)
            .pipe(
                //Returns null if it couldnet find catalog
                catchError(async () => null)
            );
    }
}
