@let feedForm = feedForm$ | async;

<header-navigation>
    <ui-button
        slot="start"
        text="CLOSE WITHOUT SAVING"
        [routerLink]="['../']"></ui-button>
    @if (feedForm) {
        <ui-button
            slot="end"
            type="primary"
            text="SAVE AND CLOSE"
            [loading]="loading()"
            (click)="saveMainFeed(feedForm)">
        </ui-button>
    }
</header-navigation>
<ui-body
    [full]="true"
    class="ui-body">
    <ui-tabs>
        <ui-tab name="Fields">
            @if (feedForm) {
                <template-fields [feedForm]="feedForm" />
            }
        </ui-tab>
        <ui-tab
            name="Creatives"
            (selectedChange)="creativeTabSelected($event)">
            @if (toggleCreativeTab && feedForm) {
                <default-creative-card [creativeField]="feedForm.controls.blueprintFields | firstcreativefield" />
            }
        </ui-tab>
        <ui-tab
            name="Operations"
            (selectedChange)="operationsTabSelected($event)">
            @if (feedForm) {
                <filters [filterForm]="feedForm.controls.filterGroups" />
            }
        </ui-tab>
    </ui-tabs>
</ui-body>
