@let creative = creative$ | async;
@let brandId = brandId$ | async;
@let dynamicFieldsFormAry = dynamicFieldsFormGroup$ | async;
@let dynamicPropertyData = (dynamicPropertyData$ | async) || [];
<div class="tw-flex tw-flex-row tw-px-25 tw-gap-6">
    <div>
        <h3>Preview</h3>

        @if (creative) {
            <paginated-creative-preview
                [creative]="creative"
                [dynamicPropertyData]="dynamicPropertyData" />
        } @else {
            <div class="tw-w-[300px] tw-aspect-square tw-bg-ui-waterfall">
                <div class="tw-h-full tw-flex tw-items-center tw-justify-center">
                    <ui-svg-icon
                        icon="creative-set"
                        class="icon" />
                </div>
            </div>
        }

        <div class="tw-flex tw-flex-col tw-items-start">
            <ui-button
                text="FULL-SCREEN PREVIEW"
                type="default"
                svgIcon="preview_ads"
                [border]="false"
                class="button"
                [disabled]="!creative">
            </ui-button>
            @if (brandId && creative) {
                <ui-button
                    text="OPEN CREATIVE IN STUDIO"
                    type="default"
                    svgIcon="go-to-url"
                    [border]="false"
                    class="button"
                    (click)="openCreative(brandId, creative)">
                </ui-button>
            }
        </div>
    </div>
    <div class="tw-flex-grow tw-flex-col">
        <h3>{{ creativeField().name }}</h3>
        <div class="tw-flex tw-self-start tw-w-full tw-gap-5">
            <div class="tw-mt-4 tw-gap-2 tw-flex tw-flex-col tw-items-start tw-w-[80%]">
                <span class="tw-whitespace-nowrap tw-text-sm">Select creative</span>
                <mat-form-field
                    appearance="outline"
                    class="tw-w-full"
                    (click)="selectCreativeDialog.open()">
                    <mat-label>Select creative</mat-label>
                    <input
                        (click)="selectCreativeDialog.open()"
                        matInput
                        [value]="creative ? (creative | creativeDetails) : 'Select creative'"
                        readonly />
                    <button
                        mat-button
                        matSuffix
                        (click)="selectCreativeDialog.open()"
                        class="tw-text-ui-primary tw-font-medium tw-bg-transparent tw-border-none tw-cursor-pointer tw-px-4">
                        {{ creative ? 'CHANGE' : 'SELECT' }}
                    </button>
                </mat-form-field>
            </div>
            <div class="tw-flex-grow tw-mt-4 tw-gap-2 tw-flex tw-flex-col tw-items-start">
                <span class="tw-whitespace-nowrap tw-text-sm">Use as</span>
                <mat-form-field
                    appearance="outline"
                    class="tw-w-full">
                    <input
                        matInput
                        readonly="true"
                        value="Default creative" />
                </mat-form-field>
            </div>
        </div>
        <h3 class="tw-mt-8">Mapping</h3>
        @if (creative) {
            @if (dynamicFieldsFormAry) {
                <div class="tw-w-full tw-grid tw-grid-cols-2 tw-auto-rows-auto tw-gap-y-0">
                    <h3 class="tw-text-ui-primary">Creative dynamic fields</h3>
                    <h3 class="tw-text-ui-primary">Values</h3>
                </div>
                <form>
                    <dynamic-form [formArray]="dynamicFieldsFormAry"></dynamic-form>
                </form>
            } @else {
                <p class="tw-text-sm"><i>There is no dynamic content available on this creative</i></p>
            }
        } @else {
            <p class="tw-text-sm"><i>Please select a creative to start mapping</i></p>
        }
    </div>
</div>

<ui-dialog
    #selectCreativeDialog="ui-dialog"
    [config]="{
        headerText: 'Select Creative',
        maxWidth: 1200,
        maxHeight: 1000,
        padding: 0,
        panelClass: 'tw-overflow-hidden'
    }">
    <ng-template ui-dialog-template>
        <creative-selector
            (closeDialog)="selectCreativeDialog.close()"
            (selectedCreative)="onCreativeSelected($event)"></creative-selector>
    </ng-template>
</ui-dialog>
