import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccountAccessService } from '../services/api/account-access.service';

export const slugRedirectGuard = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<true | UrlTree> | Promise<true | UrlTree> | true | UrlTree => {
    const accountAccessService = inject(AccountAccessService);
    const router = inject(Router);

    // If we're already processing a navigation, prevent infinite loop
    if (router.getCurrentNavigation()?.initialUrl.toString() !== '/') {
        return true;
    }

    return accountAccessService.getAccountInfo().pipe(
        take(1),
        map(slugs => {
            // Only redirect if we're at the root path
            if (router.url === '/' || router.url === '') {
                const targetPath = router.parseUrl(`/${slugs.accountSlug}/${slugs.brandSlug}`);
                return targetPath;
            }
            return true;
        })
    );
};
