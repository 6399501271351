import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FeedFieldType } from '../models/feed.model';
import { BaseFieldGroup } from '../models/feed-form.model';

@Pipe({
    name: 'firstcreativefield',
    standalone: true
})
export class FirstcreativefieldPipe implements PipeTransform {
    transform(value: FormArray<BaseFieldGroup<FeedFieldType>>): BaseFieldGroup<FeedFieldType> {
        const creativeField = value.controls.find(control => control.controls.$type.value === FeedFieldType.Creative);

        if (creativeField == null) {
            throw new Error('Creative field not found');
        }

        return creativeField;
    }
}
