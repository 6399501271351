import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  finalize,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs';
import { FeedFieldType, FeedUpdateInterval } from 'src/app/models/feed.model';
import { Template } from 'src/app/models/templates.model';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { MainFeedFieldsService } from 'src/app/services/api/main-feed-fields.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { TemplateService } from 'src/app/services/api/template.service';
import { StepperService } from 'src/app/services/ui/stepper.service';
import { GenericFeedFieldFormGroup, TemplateBuilderService } from 'src/app/services/ui/template-builder.service';
import { BottomNavigationComponent } from 'src/app/shared/bottom-navigation/bottom-navigation.component';
import { mapFormGroupToFeedFields } from 'src/app/utils/main-feed-helper';
import { PreviewTableComponent } from './preview-table/preview-table.component';
import { TemplateFieldsComponent } from './template-fields/template-fields.component';

@Component({
    selector: 'select-fields',
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        PreviewTableComponent,
        BottomNavigationComponent,
        TemplateFieldsComponent
    ],
    templateUrl: './select-fields.component.html',
    styleUrls: ['./select-fields.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFieldsComponent {
    private templateService = inject(TemplateService);
    private templateBuilder = inject(TemplateBuilderService);
    private feedSourceService = inject(FeedSourceService);
    private mainfeedService = inject(MainFeedService);
    private mainFeedFieldsService = inject(MainFeedFieldsService);
    private uiNotificationService = inject(UINotificationService);
    private stepperService = inject(StepperService);
    private suggestedFields$ = this.getSuggestedFields().pipe(shareReplay(1));
    mainFeedId$ = this.mainfeedService.mainFeedId$;
    loading$ = new BehaviorSubject<boolean>(false);

    templateFormGroup$ = this.suggestedFields$.pipe(
        distinctUntilChanged((prevFields, currFields) => prevFields.length === currFields.length),
        withLatestFrom(this.templateService.selectedTemplate$),
        map(([fields]) => this.templateBuilder.buildForm(fields)),
        shareReplay(1)
    );

    templateData$ = combineLatest([this.templateService.selectedTemplate$, this.templateFormGroup$, this.feedSourceService.feedSourceUrl$]).pipe(
        map(([template, formGroup, feedSourceUrl]) => ({
            template,
            formGroup,
            feedSourceUrl
        }))
    );

    saveBlueprint(templateName: string, templateId: string, formGroup: GenericFeedFieldFormGroup): void {
        this.loading$.next(true);

        const fields = mapFormGroupToFeedFields(formGroup.controls.fields);

        this.feedSourceService.feedSourceUrl$
            .pipe(
                switchMap(feedSourceUrl =>
                    this.mainfeedService.saveMainFeed(feedSourceUrl, fields, templateName, templateId)
                ),
                take(1),
                finalize(() => this.loading$.next(false))
            )
            .subscribe({
                next: data => {
                    this.uiNotificationService.open(`Saved ${data}`, {
                        type: 'success',
                        autoCloseDelay: 3000,
                        placement: 'top'
                    });
                    this.stepperService.completeStep();
                },
                error: error =>
                    this.uiNotificationService.open(error.message, {
                        type: 'error',
                        autoCloseDelay: 3000,
                        placement: 'top'
                    })
            });
    }

    updateBlueprint(templateName: string, id: string, formGroup: GenericFeedFieldFormGroup): void {
        this.loading$.next(true);

        const fields = mapFormGroupToFeedFields(formGroup.controls.fields);

        this.feedSourceService.feedSourceUrl$
            .pipe(
                switchMap(feeddSourceUrl =>
                    this.mainfeedService.updateMainFeed({
                        blueprint: { fields },
                        id,
                        name: templateName,
                        sourceUrl: feeddSourceUrl,
                        updateInterval: FeedUpdateInterval.None
                    })
                ),
                take(1),
                finalize(() => this.loading$.next(false))
            )
            .subscribe({
                next: data => {
                    this.uiNotificationService.open(`Updated ${data}`, {
                        type: 'success',
                        autoCloseDelay: 3000,
                        placement: 'top'
                    });
                    this.stepperService.completeStep();
                },
                error: error =>
                    this.uiNotificationService.open(error.message, {
                        type: 'error',
                        autoCloseDelay: 3000,
                        placement: 'top'
                    })
            });
    }

    updateTemplateLabel(template: Template) {
        this.templateService.selectTemplate({ ...template });
    }

    private getSuggestedFields() {
        const feedSourceUrl$ = this.feedSourceService.feedSourceUrl$;
        const selectedTemplate$ = this.templateService.selectedTemplate$;

        return this.mainFeedFieldsService.getSuggestedFields(feedSourceUrl$, selectedTemplate$);
    }

    clickLoadDummyData(): void {
        this.templateData$.pipe(
            take(1),
            tap(data => this.setDummyData(data))
        ).subscribe()    }

    private setDummyData(data: {
        template: Template;
        formGroup: GenericFeedFieldFormGroup;
        feedSourceUrl: string;
    }): void {

        const formArray = data.formGroup.controls.fields;
        const pathControlers = formArray.controls.filter(formgroup => formgroup.controls.type.value === FeedFieldType.Path);
        const staticTextControlers = formArray.controls.filter(formgroup => formgroup.controls.type.value === FeedFieldType.StaticText);

        this.feedSourceService.getFeedSourceFields().pipe(
            take(1),
            map(feedNodes => feedNodes.filter(feedNode => !feedNode.isArray)),
            tap(feedNodes => {
                pathControlers.forEach(control => {
                    let foundFeedNode = feedNodes.find(feedNode => control.controls.name.value == feedNode.name);

                    if(foundFeedNode == null) {
                        const randomIndex = Math.floor(Math.random() * feedNodes.length);
                        foundFeedNode = feedNodes[randomIndex];
                    }

                    control.controls.data.setValue([foundFeedNode.path]);
                });
            }),
        ).subscribe();

        staticTextControlers.forEach(control => {
            let randomText = (Math.random() + 1).toString(36).substring(13);
            control.controls.data.setValue([randomText]);
        })
    }

}
