<div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-pb-[200px]">
    <p class="tw-text-2xl">Let's <span (click)="autoFillInput()">start</span> by connecting your feed</p>
    <div class="tw-flex tw-flex-col tw-gap-5 tw-items-center tw-mb-5 tw-max-w-sm tw-w-full">
        <mat-form-field class="tw-w-full">
            <mat-label>Paste a URL to a .JSON file here...</mat-label>
            <input
                data-testid="feed-url-input"
                matInput
                placeholder="Feed URL"
                [formControl]="sourceUrlControl()" />
        </mat-form-field>
        <ng-content />
    </div>
</div>
