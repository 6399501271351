import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { RowEvent, UIColumnDef, UIModule, UITableDataSource } from '@bannerflow/ui';
import { BehaviorSubject, Observable, combineLatest, map, switchMap } from 'rxjs';
import { InfiniteScrollDirective } from 'src/app/directives/infinite-scroll.directive';
import { CreativeSetListItem, ListItemType, Path } from 'src/app/models/creative-set-folders.model';
import { CreativeItem } from 'src/app/models/creative.model';
import { AccountAccessService } from 'src/app/services/api/account-access.service';
import { ListService } from 'src/app/services/api/list.service';

type Pagination = { page: number; pageSize: number; folderId: string; search: string };

type CreativeListData = {
    searchInputValue: string;
    columnNames: UIColumnDef[];
    dataSource: UITableDataSource<CreativeSetListItem>;
    pagination: Pagination;
    totalItems: number;
    breadCrumbs: { folderId: string; folderName: string }[];
};
@Component({
    selector: 'creative-list',
    standalone: true,
    imports: [CommonModule, FormsModule, MatInputModule, UIModule, InfiniteScrollDirective],
    templateUrl: './creative-list.component.html',
    styleUrl: './creative-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreativeListComponent {
    @Output() creativeSetChange = new EventEmitter<CreativeItem>();

    private listService = inject(ListService);
    private accountAccessService = inject(AccountAccessService);
    private _loading$ = new BehaviorSubject<boolean>(false);
    private _pagination$ = new BehaviorSubject<Pagination>({
        page: 1,
        pageSize: 10,
        folderId: 'root',
        search: ''
    });

    private dataSource = new UITableDataSource<CreativeSetListItem>();
    loading$ = this._loading$.asObservable();

    creativeListData$: Observable<CreativeListData> = combineLatest([
        this._pagination$,
        this.accountAccessService.user$.pipe(map(user => user.brand.id))
    ]).pipe(
        switchMap(([pagination, brandId]) => {
            this._loading$.next(true);

            const apiCall = pagination.search
                ? this.listService.getFilteredCreativesetAndFolders(
                      pagination.search,
                      pagination.page,
                      pagination.pageSize,
                      brandId
                  )
                : this.listService.getCreativesetsandFolders(
                      pagination.page,
                      pagination.pageSize,
                      pagination.folderId,
                      brandId
                  );

            return apiCall.pipe(
                map(creativeSetsAndFolders => {
                    if (pagination.page === 1) {
                        this.dataSource.setData(creativeSetsAndFolders.items);
                    } else {
                        this.dataSource.addData(creativeSetsAndFolders.items);
                    }
                    this._loading$.next(false);
                    return {
                        pagination,
                        totalItems: creativeSetsAndFolders.totalItemsCount,
                        searchInputValue: pagination.search,
                        columnNames: [
                            { columnDef: 'name', name: 'Name', isCustom: true },
                            { columnDef: 'modifiedAt', name: 'Modified', width: '20%' }
                        ],
                        dataSource: this.dataSource,
                        breadCrumbs: this.generateBreadcrumbs(creativeSetsAndFolders.path)
                    };
                })
            );
        })
    );

    loadMore(currentPagination: Pagination, totalItems: number) {
        if (currentPagination.page * currentPagination.pageSize >= totalItems) {
            return;
        }
        this._pagination$.next({
            page: currentPagination.page + 1,
            pageSize: currentPagination.pageSize,
            folderId: currentPagination.folderId,
            search: currentPagination.search
        });
    }

    breadcrumbSelected(newFolderId: string) {
        this._pagination$.next({ page: 1, pageSize: 10, folderId: newFolderId, search: '' });
    }

    searchedText(value: string) {
        this._pagination$.next({ page: 1, pageSize: 10, folderId: 'root', search: value });
    }

    folderClicked(event: RowEvent<CreativeSetListItem>) {
        if (event.row.type === ListItemType.Folder) {
            this._pagination$.next({ page: 1, pageSize: 10, folderId: event.row.id, search: '' });
        } else {
            if (event.row.externalId) {
                const creativeSetChange = { id: event.row.externalId, name: event.row.name };
                this.creativeSetChange.emit(creativeSetChange);
            }
        }
    }

    private generateBreadcrumbs(paths: Path[]): { folderId: string; folderName: string }[] {
        return [
            { folderId: 'root', folderName: 'All' },
            ...paths.map(path => ({ folderId: path.id, folderName: path.name }))
        ];
    }
}
