import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnDestroy, signal, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UIModule, UINotificationService, UITabsComponent } from '@bannerflow/ui';
import { catchError, combineLatest, EMPTY, map, Observable, of, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { CreativeDynamicField, FeedFieldType, MainFeed } from 'src/app/models/feed.model';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { MainFilterService } from 'src/app/services/api/main-filter.service';
import { PreviewService } from 'src/app/services/api/preview.service';
import { StudioService } from 'src/app/services/api/studio.service';
import { DraggableBottomSheetService } from 'src/app/services/ui/draggable-bottom-sheet.service';
import { TabSelectionService } from 'src/app/services/ui/tab-selection.service';
import { TemplateBuilderService } from 'src/app/services/ui/template-builder.service';
import { FiltersComponent } from 'src/app/shared/filters/filters.component';
import { convertBaseFieldGroupToFeedField, convertFilterGroupFormToFilterGroups } from 'src/app/utils/main-feed-helper';
import { FirstcreativefieldPipe } from '../../../pipes/first-creative-field.pipe';
import { BottomSheetPreviewComponent } from '../../../shared/bottom-sheet-preview/bottom-sheet-preview.component';
import { DefaultCreativeCardComponent } from '../../../shared/default-creative-card/default-creative-card.component';
import { HeaderNavigationComponent } from '../../../shared/header-navigation/header-navigation.component';
import { TemplateFieldsComponent } from '../../stepper/select-fields/template-fields/template-fields.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FeedForm } from 'src/app/models/feed-form.model';

@Component({
    selector: 'editable-feed',
    standalone: true,
    imports: [
        UIModule,
        CommonModule,
        TemplateFieldsComponent,
        RouterLink,
        HeaderNavigationComponent,
        DefaultCreativeCardComponent,
        FiltersComponent,
        FirstcreativefieldPipe
    ],
    templateUrl: './editable-feed.component.html',
    styleUrl: './editable-feed.component.scss'
})
export class EditableFeedComponent implements OnDestroy {
    @ViewChild(UITabsComponent) uiTabsComponent: UITabsComponent;
    private mainFeedService = inject(MainFeedService);
    private mainFilterService = inject(MainFilterService);
    private activatedRoute = inject(ActivatedRoute);
    private templateBuilderService = inject(TemplateBuilderService);
    private uiNotificationService = inject(UINotificationService);
    private studioService = inject(StudioService);
    private draggableBottomSheetService = inject(DraggableBottomSheetService);
    private tabSelectionService = inject(TabSelectionService);
    private feedSourceService = inject(FeedSourceService);
    private previewService = inject(PreviewService);
    private router = inject(Router);
    private destroyRef = inject(DestroyRef);

    loading = signal<boolean>(false);
    toggleCreativeTab = false;
    mainFeedData$ = this.fetchMainFeedByRouteId().pipe(shareReplay(1));

    feedForm$ = this.mainFeedData$.pipe(
        tap(mainFeed => this.feedSourceService.setFeedSourceUrl(mainFeed.sourceUrl)),
        switchMap(mainFeed => {
            const creativeField = mainFeed.blueprint.fields.find(field => field.$type === FeedFieldType.Creative);
            if (creativeField && creativeField.creativeId) {
                // We assume there is always only one creative field for now
                return this.studioService.getDynamicFieldsByCreativeId(creativeField.creativeId).pipe(
                    map(dynamicFields => {
                        return {
                            ...mainFeed,
                            blueprint: {
                                ...mainFeed.blueprint,
                                fields: mainFeed.blueprint.fields.map(field => {
                                    if (field.$type === FeedFieldType.Creative) {
                                        return {
                                            ...field,
                                            mappings: dynamicFields.map(dynamicField => {
                                                const mapping = field.mappings.find(
                                                    mapping => mapping.dynamicPropertyId === dynamicField.id
                                                );

                                                if (!mapping) {
                                                    const newMapping: CreativeDynamicField = {
                                                        $type: FeedFieldType.Path,
                                                        path: '',
                                                        dynamicPropertyId: dynamicField.id,
                                                        dynamicPropertyLabel: dynamicField.label,
                                                        dynamicPropertyType: dynamicField.type
                                                    };
                                                    return newMapping;
                                                } else {
                                                    return {
                                                        ...mapping,
                                                        dynamicPropertyLabel: dynamicField.label,
                                                        dynamicPropertyType: dynamicField.type
                                                    };
                                                }
                                            })
                                        };
                                    } else {
                                        return field;
                                    }
                                })
                            }
                        };
                    })
                );
            } else {
                return of(mainFeed);
            }
        }),
        switchMap(mainFeed => {
            const feedForm = this.templateBuilderService.buildForm(mainFeed);

            return feedForm.valueChanges.pipe(
                startWith(feedForm.value),
                switchMap(_ => {
                    const fields = convertBaseFieldGroupToFeedField(feedForm.controls.blueprintFields);
                    const filters = convertFilterGroupFormToFilterGroups(feedForm.controls.filterGroups);

                    if (feedForm.valid) {
                        return this.previewService.getPreviewData({
                            source: feedForm.controls.sourceUrl.value,
                            fields: fields,
                            filters: filters
                        });
                    } else {
                        return EMPTY;
                    }
                }),
                map(() => feedForm)
            );
        })
    );

    ngOnInit(): void {
        this.tabSelectionService.selectTab$.subscribe((shouldSelectTab: boolean) => {
            if (shouldSelectTab && this.uiTabsComponent) {
                const creativesTab = this.uiTabsComponent.tabs.find(tab => tab.name === 'Creatives');
                if (creativesTab) {
                    this.uiTabsComponent.selectTab(creativesTab);
                }
            }
        });
    }

    // TODO there is a bug in ui-bannerflow tabs making creative tab initialize even tho template is not loaded
    creativeTabSelected(selected: boolean): void {
        this.toggleCreativeTab = selected;
    }

    saveMainFeed(feedForm: FeedForm): void {
        this.loading.set(true);
        const mainFeed: MainFeed = {
            name: feedForm.controls.template.controls.name.value,
            sourceUrl: feedForm.controls.sourceUrl.value,
            updateInterval: feedForm.controls.updateInterval.value,
            blueprint: {
                fields: convertBaseFieldGroupToFeedField(feedForm.controls.blueprintFields)
            }
        };

        const mainFilterPayload = {
            filterGroups: convertFilterGroupFormToFilterGroups(feedForm.controls.filterGroups)
        };

        this.mainFeedService.mainFeedId$
            .pipe(
                switchMap(mainFeedId =>
                    combineLatest({
                        mainFeed: this.mainFeedService.updateMainFeed({ ...mainFeed, id: mainFeedId }).pipe(
                            catchError(_ => {
                                // Ignore error for now since it will always fail if nothing has changed
                                return of(null);
                            })
                        ),
                        mainFilter: this.mainFilterService.saveMainFilter(mainFeedId, mainFilterPayload).pipe(
                            catchError(_ => {
                                // Ignore error for now since it will always fail if nothing has changed
                                return of(null);
                            })
                        )
                    })
                ),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: () => {
                    this.uiNotificationService.open('Saved successfully', {
                        type: 'success',
                        placement: 'top',
                        autoCloseDelay: 3000
                    });
                    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
                    this.loading.set(false);
                },
                error: () => {
                    this.loading.set(false);
                }
            });
    }

    operationsTabSelected(selected: boolean): void {
        if (selected) {
            const componentPortal = new ComponentPortal(BottomSheetPreviewComponent);
            this.draggableBottomSheetService.createBottomSheet(componentPortal);
        } else {
            this.draggableBottomSheetService.removeBottomSheet();
        }
    }

    private fetchMainFeedByRouteId(): Observable<Required<MainFeed>> {
        return this.activatedRoute.params.pipe(
            switchMap(params => {
                this.mainFeedService.setMainFeedId(params['id']);
                return this.mainFeedService.getMainFeedById(params['id']);
            })
        );
    }

    ngOnDestroy(): void {
        this.draggableBottomSheetService.removeBottomSheet();
    }
}
