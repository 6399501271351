@if (this.filterForm().controls.length > 0) {
    <div class="tw-flex tw-gap-5 tw-flex-col">
        @for (filterGroup of filterForm().controls; track filterGroup; let i = $index) {
            <div class="filter-card">
                <div class="card-header">
                    <editable-field [formCtrl]="filterGroup.controls.label">
                        <p class="tw-font-normal tw-text-sm">{{ filterGroup.controls.label.value }}</p>
                    </editable-field>
                    @if (!filterGroup.controls.isFilterGroupOpen.value) {
                        <filter-summary [filterGroup]="filterGroup"></filter-summary>
                    }
                    <div class="action">
                        <ui-svg-icon
                            [icon]="filterGroup.controls.isFilterGroupOpen.value ? 'arrow-up' : 'arrow-down'"
                            (click)="toggleCard(filterGroup.controls.isFilterGroupOpen)"
                            class="icon">
                        </ui-svg-icon>
                        <ui-svg-icon
                            [icon]="'delete'"
                            (click)="removeFilterGroup(i)"
                            class="icon">
                        </ui-svg-icon>
                    </div>
                </div>
                @if (filterGroup.controls.isFilterGroupOpen.value) {
                    <div class="tw-flex tw-flex-row tw-gap-4 tw-items-start tw-mt-5">
                        <p class="tw-text-sm tw-whitespace-nowrap tw-flex tw-items-center tw-my-5">I want to</p>
                        <mat-form-field
                            appearance="outline"
                            class="tw-w-full tw-max-w-xs">
                            <mat-select
                                placeholder="Select action"
                                #actionSelect
                                [value]="actionOptions[0]"
                                hideSingleSelectionIndicator>
                                <mat-select-trigger>
                                    <ui-svg-icon [icon]="actionSelect.value?.icon"></ui-svg-icon>&nbsp;
                                    {{ actionSelect.value?.label }}
                                </mat-select-trigger>
                                @for (action of actionOptions; track action) {
                                    <mat-option
                                        class="tw-flex tw-items-center tw-gap-1"
                                        [value]="action">
                                        <ui-svg-icon [icon]="action.icon" />
                                        &nbsp;{{ action.label }}
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <div class="grid-column tw-gap-4 tw-items-center tw-w-full">
                            @for (filter of filterGroup.controls.filters.controls; track filter; let j = $index) {
                                @if (j === 0) {
                                    <path-field
                                        [pathFieldControl]="filter.controls.field"
                                        name="Select field" />
                                    <p class="tw-text-sm tw-whitespace-nowrap tw-flex">so it</p>
                                    <mat-form-field
                                        appearance="outline"
                                        class="tw-w-full tw-max-w-xs tw-h-[57px]">
                                        <mat-select
                                            placeholder="Select operator"
                                            [formControl]="filter.controls.operator"
                                            hideSingleSelectionIndicator
                                            dynamicDropdown>
                                            @for (operator of operators$ | async; track operator) {
                                                <mat-option [value]="operator.value">{{ operator.label }}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                    <p class="tw-text-sm tw-flex">following</p>
                                }
                                <div
                                    class="tw-flex tw-flex-col tw-w-full"
                                    [ngClass]="{ 'tw-col-start-5 tw-col-end-5': j > 0 }">
                                    @for (
                                        argument of filter.controls.arguments.controls;
                                        track argument;
                                        let k = $index
                                    ) {
                                        <div class="tw-flex tw-items-center tw-justify-center tw-gap-4">
                                            <mat-form-field
                                                appearance="outline"
                                                class="tw-w-full">
                                                <input
                                                    matInput
                                                    [formControl]="argument"
                                                    placeholder="Type to start selecting..." />
                                            </mat-form-field>
                                            <div class="tw-flex tw-gap-4 tw-items-center">
                                                <ui-button
                                                    type="default"
                                                    svgIcon="plus-small"
                                                    [disabled]="
                                                        filterGroup.invalid ||
                                                        !(j === filterGroup.controls.filters.length - 1) ||
                                                        !(k === filter.controls.arguments.length - 1)
                                                    "
                                                    (click)="addFilter(filterGroup, j)"></ui-button>
                                                <ui-button
                                                    type="default"
                                                    svgIcon="minus-small"
                                                    [disabled]="filterGroup.controls.filters.length === 1"
                                                    (click)="removefilter(filterGroup, j)"></ui-button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
                @if (filterGroup.controls.isFilterGroupOpen.value) {
                    <div
                        class="radio-button"
                        [formGroup]="filterGroup">
                        <mat-radio-group formControlName="filterType">
                            <mat-radio-button value="Include">include</mat-radio-button>
                            <mat-radio-button value="Exclude">exclude</mat-radio-button>
                            <ng-content select="[radio-button]"></ng-content>
                        </mat-radio-group>
                    </div>
                }
            </div>
        }
    </div>
    @if (filterForm().controls.length > 0) {
        <div class="tw-flex tw-my-7 tw-justify-end">
            <ui-button
                type="default"
                svgIcon="plus-small"
                class="add-filter-button"
                [border]="false"
                [text]="'NEW OPERATION'"
                [disabled]="filterForm().invalid"
                (click)="addGroupFilter()"></ui-button>
        </div>
    }
    <ng-content select="[preview]"></ng-content>
} @else {
    <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4">
        <p>Here you can create filters to narrow down the results of your feed</p>
        <ui-button
            type="default"
            [svgIcon]="'plus-small'"
            [text]="'LET\'S CREATE YOUR FIRST OPERATION'"
            (click)="addGroupFilter()">
        </ui-button>
    </div>
}
