import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UIModule } from '@bannerflow/ui';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import {
  Template,
  TemplateOptionalField,
  TemplateOptionalFieldsResponse
} from 'src/app/models/templates.model';
import { SocialFieldsService } from 'src/app/services/api/social-fields.service';
import { TemplateService } from 'src/app/services/api/template.service';

@Component({
    selector: 'add-field',
    standalone: true,
    imports: [CommonModule, UIModule, MatFormFieldModule, MatSelectModule, MatInputModule, FormsModule],
    templateUrl: './add-field.component.html',
    styleUrls: ['./add-field.component.scss']
})
export class AddFieldComponent implements OnInit {
    @Input() template: Template;
    @Output() addNewField = new EventEmitter<string>();
    @ViewChild('addFieldWrapper') private addFieldWrapper: ElementRef;

    private socialFieldsService = inject(SocialFieldsService);
    socialMediaOptionalFields$: Observable<TemplateOptionalFieldsResponse>;
    selectedFields = new SelectionModel<string>(true, []);

    ngOnInit() {
        if (this.template.type !== 'DisplayTemplate') {
            this.socialMediaOptionalFields$ = combineLatest([
                this.socialFieldsService.getSocialFields(this.template.id),
                this.selectedFields.changed.pipe(startWith(null))
            ]).pipe(
                map(([fields, _]) => {
                    fields.optionalFields = fields.optionalFields.filter((field: TemplateOptionalField) => {
                        return !this.selectedFields.isSelected(field.label);
                    });
                    return fields;
                })
            );
        }
    }

    addDisplayField(): void {
        this.addNewField.emit();
        this.scrollToBottom();
    }

    addSDAField(selectedField: string): void {
        this.selectedFields.select(selectedField);
        this.addNewField.emit(selectedField);
        this.scrollToBottom();
    }

    private scrollToBottom(): void {
        this.addFieldWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
}
