import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UIModule } from '@bannerflow/ui';
import { BehaviorSubject, map, Observable, of, startWith, switchMap } from 'rxjs';
import { FeedFieldType } from 'src/app/models/feed.model';
import { PreviewService } from 'src/app/services/api/preview.service';
import { TemplateBuilderService } from 'src/app/services/ui/template-builder.service';
import { AddFieldComponent } from 'src/app/shared/add-field/add-field.component';
import { EditableFieldComponent } from 'src/app/shared/editable-field/editable-field.component';
import { PreviewValueComponent } from 'src/app/shared/preview-value/preview-value.component';
import { convertBaseFieldGroupToFeedField, transformFilterFormToFilterGroups } from 'src/app/utils/main-feed-helper';
import { v4 as uuidv4 } from 'uuid';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { FeedForm } from 'src/app/models/feed-form.model';

@Component({
    selector: 'template-fields',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        UIModule,
        DynamicFormComponent,
        EditableFieldComponent,
        AddFieldComponent,
        PreviewValueComponent
    ],
    templateUrl: './template-fields.component.html',
    styleUrl: './template-fields.component.scss'
})
export class TemplateFieldsComponent {
    feedForm = input.required<FeedForm>();

    previewService = inject(PreviewService);
    templateBuilder = inject(TemplateBuilderService);
    currentPage$ = new BehaviorSubject<number>(1);
    previewData$: Observable<{ data: any[]; total: number }>;

    ngOnInit() {
        this.previewData$ = this.feedForm().controls.blueprintFields.valueChanges.pipe(
            startWith(this.feedForm().controls.blueprintFields.value),
            map(_ => {
                //Filter fields to remove those with an empty path
                const filteredFields = convertBaseFieldGroupToFeedField(this.feedForm().controls.blueprintFields)
                    .filter(
                        field =>
                            (field.$type == FeedFieldType.Path && field.path != '') ||
                            (field.$type == FeedFieldType.StaticText && field.text != '') ||
                            (field.$type == FeedFieldType.Creative && field.creativeId != '')
                    )
                    .map(field => {
                        // TODO should be removed since there is no use for children, but fearsome expects the property atm.
                        if (field.$type == FeedFieldType.Path) {
                            return { ...field, children: [] };
                        } else {
                            return field;
                        }
                    });

                const filters = transformFilterFormToFilterGroups(this.feedForm().controls.filterGroups);

                return {
                    fields: filteredFields || [],
                    feedSourceUrl: this.feedForm().controls.sourceUrl.value,
                    filters: filters
                };
            }),
            switchMap(({ fields, feedSourceUrl, filters }) => {
                // Only proceed if there are any non-empty fields
                if (fields.length > 0) {
                    return this.previewService.getPreviewData({
                        source: feedSourceUrl,
                        fields: fields,
                        filters: filters
                    });
                } else {
                    // Return an observable that emits nothing
                    return of({ data: [], total: 0 });
                }
            }),
            startWith({ data: [], total: 0 })
        );
    }

    previewPrevious(currentPage: number): void {
        currentPage = currentPage - 1;
        this.currentPage$.next(currentPage);
    }

    previewNext(currentPage: number): void {
        currentPage = currentPage + 1;
        this.currentPage$.next(currentPage);
    }

    createNewField(fieldName: string): void {
        const newBaseFieldGroup = this.templateBuilder.buildFieldGroup(
            {
                $type: FeedFieldType.Path,
                name: fieldName ? fieldName : 'New field',
                required: false,
                path: '',
                id: uuidv4()
            },
            true,
            false
        );
        this.feedForm().controls.blueprintFields.push(newBaseFieldGroup);
    }
}
