<ui-tabs
    [attr.data-testid]="'field-editor-tabs'"
    class="ui-tabs">
    <ui-tab
        name="EDIT FIELD"
        headerId="edit-tab">
        <mat-form-field>
            
        <input
            matInput 
            #editInput
            type="text"
            [formControl]="fieldInputFormControl">      
        </mat-form-field>
        @if(fieldInputFormControl.hasError('invalidSyntax')){
            <mat-error>
                Invalid Syntax
            </mat-error>
        }
        <p class="preview-text">Preview</p>
        {{ previewValue }}

    </ui-tab>
    <ui-tab
        name="APPLY FILTERS"
        headerId="filter-tab">
    </ui-tab>
    <div class="btn-wrapper">
        <ui-button
            type="default"
            text="Cancel"
            [attr.data-testid]="'edit-cancel'"
            (click)="closeDialog.emit()">
        </ui-button>
        <ui-button
            id="interaction-edit-confirm"
            type="primary"
            text="Confirm"
            [disabled]="fieldInputFormControl.hasError('invalidSyntax')"
            [attr.data-testid]="'edit-confirm'"
            (click)="onClickConfirm()">
        </ui-button>
    </div>
</ui-tabs>
