import { ArrayDataSource } from '@angular/cdk/collections';
import { OverlayModule } from '@angular/cdk/overlay';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { Component, inject, input, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UIModule } from '@bannerflow/ui';
import { map } from 'rxjs';
import { SplitLastPipe } from 'src/app/pipes/split-last.pipe';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { FeedTreeFieldsComponent } from './feed-tree-fields/feed-tree-fields.component';
@Component({
    selector: 'path-field',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatIconModule,
        FeedTreeFieldsComponent,
        UIModule,
        SplitLastPipe,
        TitleCasePipe,
        OverlayModule,
        AsyncPipe
    ],
    templateUrl: './path-field.component.html',
    styleUrl: './path-field.component.scss'
})
export class PathFieldComponent {
    pathFieldControl = input.required<FormControl<string>>();
    name = input.required<string>();

    // TODO remove with SYN-1940
    chipClicked = output<void>();

    feedSourceService = inject(FeedSourceService);

    isOpen = false;
    feedSchemaSource$ = this.feedSourceService.getFeedSourceFields().pipe(map(nodes => new ArrayDataSource(nodes)));

    add(event: MatChipInputEvent): void {
        this.setChipValue(event.value);
        event.chipInput!.clear();
    }

    setChipValue(value: string): void {
        if (value == '') throw new Error('Empty chip value is not allowed');

        value = value.trim();

        this.pathFieldControl().setValue(value);
    }

    removeField() {
        this.pathFieldControl().setValue('');
    }

    openSelect(event: Event) {
        event.stopPropagation();
        this.isOpen = !this.isOpen;
    }

    onChipClick(event: Event): void {
        event.stopPropagation();
        this.chipClicked.emit();
    }
}
