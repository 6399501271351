import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FeedField } from 'src/app/models/feed.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MainFeedFieldsService {
    private http = inject(HttpClient);

    getSuggestedFields(templateId: string): Observable<FeedField[]> {
        return this.http.post<FeedField[]>(`${environment.origins.feedsSupremeCommander}/api/MainFeeds/blueprint`, {
            templateId
        });
    }
}
