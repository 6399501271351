import { Component, ElementRef, inject, Input, SimpleChanges, ViewChild } from '@angular/core';
import { CREATIVE_PREVIEW_HOST, UICreativePreviewComponent, UIModule } from '@bannerflow/ui';
import { Creative } from 'src/app/models/creative.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'creative-preview',
    standalone: true,
    imports: [UIModule, UICreativePreviewComponent],
    providers: [{ provide: CREATIVE_PREVIEW_HOST, useValue: environment.origins.creativePreviewUrl }],
    templateUrl: './creative-preview.component.html',
    styleUrl: './creative-preview.component.scss'
})
export class CreativePreviewComponent {
    @Input() selectedCreative: Creative;
    @ViewChild('previewContainer') private previewContainer: ElementRef;
    private resizeObserver: ResizeObserver;
    newCreativeWidth: number;
    containerWidth: number;
    containerHeight: number;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedCreative']) {
            this.loadCreativePreview();
        }
    }

    ngAfterViewInit(): void {
        this.resizeObserver = new ResizeObserver(() => {
            this.containerWidth = this.previewContainer.nativeElement.offsetWidth;
            this.containerHeight = this.previewContainer.nativeElement.offsetHeight;

            if (this.selectedCreative) {
                this.loadCreativePreview();
            }
        });

        if (this.previewContainer && this.previewContainer.nativeElement) {
            this.resizeObserver.observe(this.previewContainer.nativeElement);
        }
    }

    ngOnDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    private loadCreativePreview(): void {
        if (this.selectedCreative) {
            const creativeWidth = this.selectedCreative.size.width;
            const creativeHeight = this.selectedCreative.size.height;
            this.newCreativeWidth = this.adjustPreviewWidthToContainer(creativeWidth, creativeHeight);
        }
    }

    private adjustPreviewWidthToContainer(creativeWidth: number, creativeHeight: number): number {
            const scalingRatio = this.containerHeight / creativeHeight;
            const adjustedImageWidth = creativeWidth * scalingRatio;
            const adjustedWidthPercentage = Math.ceil((adjustedImageWidth / this.containerWidth) * 100);

            return adjustedWidthPercentage > 100 ? 100 : adjustedWidthPercentage;
    }
}
