import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Brand } from '../../models/brand.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BrandService {
    http = inject(HttpClient);

    getBrandById(brandId: string): Observable<Brand> {
        return this.http.get<Brand>(
            `${environment.origins.brandManager}/brand/${brandId}?includeAllLocalizations=true`
        );
    }
}
