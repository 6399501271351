import { Directive, ElementRef, Input, Renderer2, inject } from '@angular/core';

@Directive({
    selector: '[sizeThumbnail]',
    standalone: true
})
export class SizeThumbnailDirective {
    @Input() originalWidth!: number;
    @Input() originalHeight!: number;

    elRef = inject(ElementRef);
    renderer = inject(Renderer2);

    private maxDimension: number = 16;

    ngOnInit(): void {
        this.resizeElement();
    }

    private resizeElement(): void {
        const originalWidth = this.originalWidth;
        const originalHeight = this.originalHeight;
        let newWidth: number;
        let newHeight: number;

        if (originalWidth > originalHeight) {
            newWidth = this.maxDimension;
            newHeight = (originalHeight / originalWidth) * newWidth;
        } else if (originalHeight > originalWidth) {
            newHeight = this.maxDimension;
            newWidth = (originalWidth / originalHeight) * newHeight;
        } else {
            newWidth = this.maxDimension;
            newHeight = this.maxDimension;
        }

        this.renderer.setStyle(this.elRef.nativeElement, 'width', `${newWidth}px`);
        this.renderer.setStyle(this.elRef.nativeElement, 'height', `${newHeight}px`);
    }
}
