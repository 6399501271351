export interface OperationsSummary {
    label: string;
    type: OperationsType;
    summary: string[];
}

export enum OperationsType {
    Filter = 'filter',
    Priority = 'Priority',
    Sort = 'Sort',
    ReplaceText = 'Replace Text'
}
