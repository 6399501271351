import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterGroups } from '../../models/filters.model';

interface FilterGroupsResponse {
    filterGroups: FilterGroups;
}
@Injectable({
    providedIn: 'root'
})
export class MainFilterService {
    private http = inject(HttpClient);
    private BASE_URL = `${environment.origins.feedsSupremeCommander}/api/MainFeeds`;

    saveMainFilter(feedId: string, filterGroups: FilterGroups): Observable<HttpResponse<void>> {
        return this.http
            .put<HttpResponse<void>>(`${this.BASE_URL}/${feedId}/filters`, filterGroups)
            .pipe(catchError(err => this.handleError(err)));
    }

    getFiltersById(feedId: string): Observable<FilterGroups> {
        const url = `${this.BASE_URL}/${feedId}/filters`;
        return this.http.get<FilterGroupsResponse>(url).pipe(map(val => val.filterGroups));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 404) {
            // Handle 404 errors differently if needed
            return throwError(() => new Error('Something went wrong. Please contact support.'));
        }
        // Return a more generic message for other errors
        return throwError(() => new Error(`An error occurred while saving the filters. Please try again later.`));
    }
}
