import { ApplicationConfig } from '@angular/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { bfHeaderInterceptor } from './interceptors/bf-header.interceptor';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(withInterceptors([bfHeaderInterceptor, authHttpInterceptorFn])),
        provideRouter(routes),
        provideAuth0({
            clientId: environment.auth.clientId,
            domain: environment.auth.domain,
            authorizationParams: {
                redirect_uri: window.location.search.includes('branch=')
                    ? `${window.location.origin}/${window.location.search}`
                    : `${window.location.origin}`,
                audience: environment.auth.audience,
                scope: 'listservice feedssupremecommander feedsdeliverymaster feedssocializingmaster feedsfearsomeprocessor studio bannerflow profile socialaccountservice'
            },
            httpInterceptor: {
                allowedList: Object.values(environment.origins).map(origin => ({
                    uri: `${origin}/*`,
                    tokenOptions: {
                        authorizationParams: {
                            audience: environment.auth.audience
                        }
                    }
                }))
            },
            useRefreshTokens: true
        }),
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline', subscriptSizing: 'dynamic' }
        }
    ]
};
