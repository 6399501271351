import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { UIModule } from '@bannerflow/ui';
import { FeedFieldType } from 'src/app/models/feed.model';

interface FieldType {
    value: FeedFieldType;
    viewValue: string;
}

@Component({
    selector: 'select-type',
    standalone: true,
    imports: [CommonModule, UIModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule],
    templateUrl: './select-type.component.html',
    styleUrl: './select-type.component.scss'
})
export class SelectTypeComponent {
    bluePrintFieldTypeControl = input.required<FormControl<FeedFieldType>>();
    fieldTypeChanged = output<FeedFieldType>();
    isDynamicFieldContent = input<boolean>(false);

    fieldTypes: FieldType[] = [
        { value: FeedFieldType.Path, viewValue: 'Feed' },
        { value: FeedFieldType.StaticText, viewValue: 'Static Text' },
        { value: FeedFieldType.Creative, viewValue: 'Creative' }
    ];

    ngOnInit(): void {
        this.fieldTypes = this.filteredFieldTypes();
    }

    filteredFieldTypes(): FieldType[] {
        if (this.isDynamicFieldContent()) {
            return this.fieldTypes.filter(field => field.value !== FeedFieldType.Creative);
        }
        return this.fieldTypes;
    }

    selectionChange(type: FeedFieldType): void {
        this.bluePrintFieldTypeControl().setValue(type, { emitEvent: false });
        this.fieldTypeChanged.emit(type);
    }
}
