@let fieldsTabData = fieldsTabData$ | async;
@let creativeTabData = creativeTabData$ | async;
@let operationsTabData = operationsTabData$ | async;

@if (fieldsTabData) {
    <header-navigation>
        <ui-button
            slot="start"
            text="CLOSE WITHOUT SAVING"
            [routerLink]="['../']"></ui-button>
        <ui-button
            slot="end"
            type="primary"
            text="SAVE AND CLOSE"
            [loading]="(loading$ | async)!"
            (click)="
                updateMainFeed(
                    fieldsTabData.template.label,
                    fieldsTabData.feedId,
                    fieldsTabData.formGroup,
                    fieldsTabData.sourceUrl,
                    fieldsTabData.updateInterval!
                )
            ">
        </ui-button>
    </header-navigation>
}
<ui-body
    [full]="true"
    class="ui-body">
    <ui-tabs>
        <ui-tab name="Fields">
            @if (fieldsTabData) {
                <template-fields
                    [template]="fieldsTabData.template"
                    [feedSourceUrl]="fieldsTabData.sourceUrl"
                    [formGroup]="fieldsTabData.formGroup"
                    (updateTemplateLabel)="updateTemplateLabel($event)" />
            }
        </ui-tab>
        <ui-tab
            name="Creatives"
            (selectedChange)="creativeTabSelected($event)">
            @if (creativeTabData && toggleCreativeTab) {
                <default-creative-card
                    [creativeField]="creativeTabData.creativeField"
                    [creative]="creativeTabData.creative"
                    (dynamicPropertiesChanged)="setCreative($event)" />
            }
        </ui-tab>
        <ui-tab
            name="Operations"
            (selectedChange)="operationsTabSelected($event)">
            @if (operationsTabData) {
                <filters
                    [filterGroups]="operationsTabData"
                    (filtersChange)="setFilters($event)" />
            }
        </ui-tab>
    </ui-tabs>
</ui-body>
