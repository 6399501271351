import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import { BehaviorSubject, combineLatest, filter, finalize, map, switchMap, take } from 'rxjs';
import { CreativeDynamicField, FeedFieldType } from 'src/app/models/feed.model';
import { DynamicFieldsService } from 'src/app/services/api/dynamic-fields.service';
import { MainFeedFieldsService } from 'src/app/services/api/main-feed-fields.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { StepperService } from 'src/app/services/ui/stepper.service';
import { BottomNavigationComponent } from 'src/app/shared/bottom-navigation/bottom-navigation.component';
import { CreativeSelectorComponent } from 'src/app/shared/creative-selector/creative-selector.component';
import { DefaultCreativeCardComponent } from 'src/app/shared/default-creative-card/default-creative-card.component';

@Component({
    selector: 'connect-creative',
    standalone: true,
    imports: [BottomNavigationComponent, UIModule, DefaultCreativeCardComponent, CreativeSelectorComponent, AsyncPipe],
    templateUrl: './connect-creative.component.html',
    styleUrl: './connect-creative.component.scss'
})
export class ConnectCreativeComponent {
    private destroyRef = inject(DestroyRef);
    private stepperService = inject(StepperService);
    private dynamicFieldsService = inject(DynamicFieldsService);
    private mainFeedService = inject(MainFeedService);
    private uiNotificationService = inject(UINotificationService);
    private mainFeedFieldsService = inject(MainFeedFieldsService);

    private mainFeedId$ = this.mainFeedService.mainFeedId$;
    private creativeDynamicFieldsData$ = new BehaviorSubject<{
        creativeId: string;
        creativeSetId: string;
        fields: CreativeDynamicField[];
    }>({ creativeId: '', creativeSetId: '', fields: [] });
    private creativeFields$ = this.mainFeedFieldsService.fields$.pipe(
        map(fields =>
            fields
                .filter(field => field.$type === FeedFieldType.Creative)
                .map(field => ({ type: field.$type, ...field }))
        )
    );

    // TODO: Temporary solution, in the future we will have multiple creative fields
    // and we should be able to use creativeFields$ directly to render
    // single/multiple "DefaultCreativeCardComponent" or multiple "ConditionalCreativeCardComponent"
    singleCreativeField$ = this.creativeFields$.pipe(map(fields => fields[0]));

    loading = false;

    setCreativeDynamicFields(data: {
        creativeId: string;
        creativeSetId: string;
        fields: CreativeDynamicField[];
    }): void {
        this.creativeDynamicFieldsData$.next(data);
    }

    saveCreativeCards(): void {
        this.loading = true;
        combineLatest([this.creativeDynamicFieldsData$, this.singleCreativeField$, this.mainFeedId$])
            .pipe(
                take(1),
                filter(([dynamicField]) => {
                    const isCreativeSelected = dynamicField.creativeId !== '';
                    if (!isCreativeSelected) {
                        this.stepperService.nextStep();
                    }
                    return isCreativeSelected;
                }),
                switchMap(([dynamicFieldsData, creativeField, mainFeedId]) =>
                    this.dynamicFieldsService.updateCreativeField({
                        fieldId: creativeField.id,
                        creativeSetId: dynamicFieldsData.creativeSetId,
                        creativeId: dynamicFieldsData.creativeId,
                        mainFeedId,
                        fields: dynamicFieldsData.fields
                    })
                ),
                finalize(() => {
                    this.loading = false;
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: () => {
                    this.loading = false;
                    this.stepperService.nextStep();
                },
                error: () => {
                    this.uiNotificationService.open('Error saving creative dynamic fields', {
                        type: 'error',
                        autoCloseDelay: 3000,
                        placement: 'top'
                    });
                }
            });
    }
}
