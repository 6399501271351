import { FilterGroups } from './filters.model';



export interface StudioDynamicFieldBase {
    label: string;
    id: string;
    type: DynamicFieldType
}

export interface StudioDynamicTextField extends StudioDynamicFieldBase {
    type: 'Text'
}

export interface StudioDynamicImageField extends StudioDynamicFieldBase {
    type: 'Image'
}
interface BaseField {
    dynamicPropertyId: string;
    $type: 'StaticText' | 'Path'; // Discriminator for type checking
}

export type CreativeDynamicField = PathField | StaticTextField;
interface StaticTextField extends BaseField {
    $type: 'StaticText';
    text: string;
}

interface PathField extends BaseField {
    $type: 'Path';
    path: string;
}
export interface FeedNode {
    name: string;
    children?: FeedNode[];
    isArray: boolean;
    path: string;
    values: string[];
}

export enum FeedFieldType {
    Path = 'Path',
    StaticText = 'StaticText',
    Creative = 'Creative'
}

export type DynamicFieldType = 'Image' | 'Text';

export type FeedField = GenericPathFeedField | GenericStaticTextFeedField | GenericCreativeFeedField;
interface GenericFeedField {
    $type: FeedFieldType;
    name: string;
    id: string;
    required: boolean;
    dynamicContentType?: DynamicFieldType;
}
export interface GenericPathFeedField extends GenericFeedField {
    $type: FeedFieldType.Path;
    children?: GenericPathFeedField[];
    path: string;
    dynamicPropertyId?: string;
}
export interface GenericStaticTextFeedField extends GenericFeedField {
    $type: FeedFieldType.StaticText;
    text: string;
    dynamicPropertyId?: string;
}
export interface GenericCreativeFeedField extends GenericFeedField {
    $type: FeedFieldType.Creative;
    creativeSetId: string;
    creativeId: string;
    mappings: CreativeDynamicField[];
}
export interface FeedValueObject {
    label: string;
    value: string | null;
    targetUrl: null | string;
}

export interface FeedItem {
    data: {
        [key: string]: FeedValueObject;
    };
}

export interface PreviewData {
    data: FeedItem[];
    total: number;
}

export interface MainFeedName {
    id: string;
    name: string;
}

export interface MainFeed {
    id: string;
    name: string;
    brandId?: string;
    sourceUrl: string;
    blueprint: { fields: FeedField[] };
    filters?: FilterGroups;
    updateInterval?: FeedUpdateInterval;
    templateId?: string;
}

export interface Operator {
    label: string;
    value: DomainFunction;
}

export enum DomainFunction {
    Equal = '==',
    Greater = '>',
    Less = '<',
    GreaterEqual = '>=',
    LessEqual = '<=',
    Between = 'between',

    Contains = 'contains',
    Replace = 'replace',
    ToLower = 'toLower',

    Add = 'add',
    Divide = 'divide',
    Multiply = 'multiply',
    Subtract = 'subtract',

    DateFormat = 'dateFormat',
    DistinctBy = 'distinctBy',
    PriorityBy = 'priorityBy'
}

export enum FeedUpdateInterval {
    None = 'None',
    TwentyMinute = 'TwentyMinute',
    ThirtyMinutes = 'ThirtyMinutes',
    OneHour = 'OneHour',
    ThreeHours = 'ThreeHours',
    SixHours = 'SixHours',
    TwelveHours = 'TwelveHours',
    EveryDay = 'EveryDay',
    EveryWeek = 'EveryWeek'
}

export interface DynamicField {
    id: string;
    label: string;
    type: DynamicFieldType;
}
