import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { slugRedirectGuard } from './guards/slug-redirect.guard';
import { userGuard } from './guards/user.guard';
import { TemplistComponent } from './templist/templist.component';
import { EditableFeedComponent } from './views/feed-editor/editable-feed/editable-feed.component';
import { FeedEditorComponent } from './views/feed-editor/feed-editor.component';
import { StepperComponent } from './views/stepper/stepper.component';

export const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard, slugRedirectGuard],
        children: [
            {
                path: ':account/:brand',
                canActivate: [userGuard],
                children: [
                    { path: '', component: StepperComponent, title: 'Create a new feed' },
                    { path: 'list', component: TemplistComponent },
                    {
                        path: 'feed-editor/:id',
                        title: 'Overview your feed',
                        children: [
                            { path: '', component: FeedEditorComponent },
                            { path: 'edit', component: EditableFeedComponent, title: 'Edit your feed' }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '**',
        redirectTo: ''
    }
];
