import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'editable-field',
    standalone: true,
    imports: [CommonModule, MatFormFieldModule, UIModule, MatInputModule, ReactiveFormsModule, FormsModule],
    templateUrl: './editable-field.component.html',
    styleUrls: ['./editable-field.component.scss']
})
export class EditableFieldComponent {
    @Input() value: string;
    @Output() valueChanged = new EventEmitter<string>();
    @Input() formCtrl: FormControl;

    isEditing: boolean = false;

    save() {
        this.isEditing = false;
        this.valueChanged.emit(this.value);
    }

    cancel() {
        this.isEditing = false;
    }
}
