@if (pullRequests$ | async; as pullRequests) {
    <ui-select
        class="branch"
        [width]="'100%'"
        [useTargetWidth]="true"
        [value]="selectedBranch"
        (selectedChange)="selectedChanged($event)">
        <ui-option value="Main"> Main </ui-option>
        @for (prBranchName of pullRequests; track prBranchName) {
            <ui-option [value]="prBranchName">
                {{ prBranchName }}
            </ui-option>
        }
    </ui-select>
}
