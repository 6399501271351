import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { catchError, map, Observable, switchMap, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeedField, PreviewData } from '../../models/feed.model';
import { FilterGroup } from '../../models/filters.model';
import { FeedSourceService } from './feed-source.service';

interface PreviewDataRequest {
    id?: string;
    source: string;
    fields: FeedField[];
    filters?: FilterGroup[];
}

@Injectable({
    providedIn: 'root'
})
export class PreviewService {
    private http = inject(HttpClient);
    private feedSourceService = inject(FeedSourceService);
    private _previewData = signal<{ previewData: PreviewData; fields: FeedField[] } | undefined>(undefined);
    previewData = this._previewData.asReadonly();

    getPreviewData(request: PreviewDataRequest): Observable<PreviewData> {
        return this.http
            .post<PreviewData>(`${environment.origins.feedsFearsomeProcessor}/Preview`, {
                ...request
            })
            .pipe(tap(previewData => this._previewData.set({ previewData, fields: request.fields })));
    }

    validateFieldExpression(fields: FeedField[]): Observable<boolean> {
        return this.feedSourceService.feedSourceUrl$.pipe(
            take(1),
            switchMap(url => {
                const request = {
                    source: url,
                    fields: fields,
                    filters: []
                };
                return this.getPreviewData(request)
                    .pipe(
                        map(previewData => {
                            if (previewData == undefined) return false;

                            return true;
                        })
                    )
                    .pipe(catchError(async () => false));
            })
        );
    }
}
