import { CommonModule } from '@angular/common';
import { Component, ContentChild, inject, input, TemplateRef } from '@angular/core';
import { FormArray, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { FeedFieldType } from 'src/app/models/feed.model';
import { CreativeFieldComponent } from 'src/app/shared/creative-field/creative-field.component';
import { EditableFieldComponent } from 'src/app/shared/editable-field/editable-field.component';
import { PathFieldComponent } from 'src/app/shared/path-field/path-field.component';
import { StaticTextFieldComponent } from 'src/app/shared/static-text-field/static-text-field.component';
import { FieldEditorComponent } from '../field-editor/field-editor.component';
import { SelectTypeComponent } from '../select-type/select-type.component';
import { BaseFieldGroup, MetaDataFormGroupByType } from 'src/app/models/feed-form.model';

@Component({
    selector: 'dynamic-form',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        EditableFieldComponent,
        UIModule,
        SelectTypeComponent,
        FieldEditorComponent,
        PathFieldComponent,
        CreativeFieldComponent,
        StaticTextFieldComponent
    ],
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent {
    @ContentChild('header', { static: false }) headerTemplateRef: TemplateRef<any>;
    bluePrintFieldsFormAry = input.required<FormArray<BaseFieldGroup>>();
    kebabMenuOptions = ['Delete', 'Rename'];
    router = inject(Router);

    FeedFieldType = FeedFieldType;

    // TODO: Remove this when we have a better solution its not optimal to check the url like this
    // This is used to determine if we are in the setup flow or not
    canConnectCreative = this.router.url.includes('/edit');

    onKebabMenuOptionClicked(option: string, index: number): void {
        if (option === 'Delete') {
            this.deleteField(index);
        }
    }

    deleteField(index: number): void {
        this.bluePrintFieldsFormAry().removeAt(index);
    }

    resetMetaData(feedFieldType: FeedFieldType, metaData: MetaDataFormGroupByType) {
        metaData.reset();
        metaData.controls.Path.controls.path.clearValidators();
        metaData.controls.Path.controls.path.updateValueAndValidity();
        metaData.controls.StaticText.controls.text.clearValidators();
        metaData.controls.StaticText.controls.text.updateValueAndValidity();

        switch (feedFieldType) {
            case FeedFieldType.Path:
                metaData.controls.Path.controls.path.setValidators(Validators.required);
                metaData.controls.Path.controls.path.updateValueAndValidity();
                break;
            case FeedFieldType.StaticText:
                metaData.controls.StaticText.controls.text.setValidators(Validators.required);
                metaData.controls.StaticText.controls.text.updateValueAndValidity();
                break;
        }
    }
}
