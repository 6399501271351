import { ArrayDataSource } from '@angular/cdk/collections';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UIModule } from '@bannerflow/ui';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  startWith,
  switchMap
} from 'rxjs';
import { FeedFieldType, GenericExpressionFeedField } from 'src/app/models/feed.model';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { PreviewService } from 'src/app/services/api/preview.service';
import { FeedTreeFieldsComponent } from 'src/app/shared/feed-tree-fields/feed-tree-fields.component';
import { getPreviewData } from 'src/app/utils/preview-data.helper';
import { validateExpression } from 'src/app/utils/validator-helper';
import { PreviewValueComponent } from '../../preview-value/preview-value.component';

@Component({
    selector: 'expression-field-editor',
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        FeedTreeFieldsComponent,
        OverlayModule,
        PreviewValueComponent
    ],
    templateUrl: './expression-field-editor.component.html',
    styleUrl: './expression-field-editor.component.scss'
})
export class ExpressionFieldEditorComponent {
    expressionFormControl = input.required<FormControl<string>>();
    fieldName = input.required<string>();
    fieldId = input.required<string>();
    closeDialog = output<void>();
    readonly previewService = inject(PreviewService);
    readonly feedSourceService = inject(FeedSourceService);
    feedSchemaSource$ = this.feedSourceService.getFeedSourceFields().pipe(map(nodes => new ArrayDataSource(nodes)));
    feedSourceUrl$ = this.feedSourceService.feedSourceUrl$.pipe(map(url => url));
    previewData$: Observable<string>;
    newExpressionFormControl = new FormControl<string>('', { nonNullable: true, validators: validateExpression() });

    isOpen = false;

    constructor() {
        effect(() => {
            if (this.expressionFormControl()) {
                this.newExpressionFormControl.setValue(this.expressionFormControl().value);
            }
        });
    }

    ngOnInit() {
        this.previewData$ = combineLatest([
            this.newExpressionFormControl.valueChanges.pipe(
                distinctUntilChanged(),
                startWith(this.expressionFormControl().value),
                filter(expressionValue => {
                    return expressionValue !== null && expressionValue.trim() !== '';
                })
            ),
            this.feedSourceUrl$
        ]).pipe(
            switchMap(([expressionValue, feedSourceUrl]) => {

              const expressionField : GenericExpressionFeedField = {
                $type: FeedFieldType.Expression,
                id: this.fieldId(),
                name: this.fieldName(),
                required: true,
                expression: expressionValue
              }

                return getPreviewData(
                    this.previewService,
                    expressionField,
                    this.fieldName(),
                    feedSourceUrl
                );
            })
        );
    }

    onClickConfirm() {
        const newValue = this.newExpressionFormControl.value;

        if (this.newExpressionFormControl.valid && newValue) {
            this.expressionFormControl().setValue(newValue);

            this.closeDialog.emit();
        }
    }

    openSelect(event: Event) {
        event.stopPropagation();
        this.isOpen = !this.isOpen;
    }
}
