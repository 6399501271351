import { Component, inject } from '@angular/core';
import { DraggableBottomSheetService } from 'src/app/services/ui/draggable-bottom-sheet.service';
import { PreviewTableComponent } from 'src/app/views/stepper/select-fields/preview-table/preview-table.component';

@Component({
    selector: 'draggable-bottom-sheet',
    standalone: true,
    imports: [PreviewTableComponent],
    templateUrl: './draggable-bottom-sheet.component.html',
    styleUrl: './draggable-bottom-sheet.component.scss'
})
export class DraggableBottomSheetComponent {
    draggableBottomSheetService = inject(DraggableBottomSheetService);

    dragStart() {
        this.draggableBottomSheetService.startDrag();
    }
}
