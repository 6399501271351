import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'connect-feed',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        UIModule,
        RouterModule
    ],
    templateUrl: './connect-feed.component.html',
    styleUrls: ['./connect-feed.component.scss']
})
export class ConnectFeedComponent {
    sourceUrlControl = input.required<FormControl<string>>();

    autoFillInput(): void {
        this.sourceUrlControl().setValue('https://bffeedsv2testst.blob.core.windows.net/feeds/iGaming-1.json');
    }
}
