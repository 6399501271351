import { ArrayDataSource } from '@angular/cdk/collections';
import { OverlayModule } from '@angular/cdk/overlay';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { Component, computed, EventEmitter, inject, input, Output, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UIDialogDirective, UIModule } from '@bannerflow/ui';
import { fromEvent, map, startWith } from 'rxjs';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { FeedTreeFieldsComponent } from 'src/app/shared/feed-tree-fields/feed-tree-fields.component';
import { PathFieldEditorComponent } from './path-field-editor/path-field-editor.component';
@Component({
    selector: 'path-field',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatIconModule,
        FeedTreeFieldsComponent,
        UIModule,
        TitleCasePipe,
        OverlayModule,
        AsyncPipe,
        PathFieldEditorComponent
    ],
    templateUrl: './path-field.component.html',
    styleUrl: './path-field.component.scss'
})
export class PathFieldComponent {
    pathFieldControl = input.required<FormControl<string>>();
    fieldName = input.required<string>();
    fieldId = input<string>();
    @Output() chipClicked = new EventEmitter();
    @ViewChild('fieldEditorDialog') fieldEditorDialog: UIDialogDirective;
    feedSourceService = inject(FeedSourceService);
    isOpen = false;
    feedSchemaSource$ = this.feedSourceService.getFeedSourceFields().pipe(map(nodes => new ArrayDataSource(nodes)));
    windowResize = toSignal(
        fromEvent(window, 'resize').pipe(
            startWith(window.innerHeight),
            map(() => window.innerHeight)
        ),
        { initialValue: window.innerHeight }
    );
    overlayHeight = computed(() => {
        return Math.min(this.windowResize() * 0.9, 500);
    });

    add(event: MatChipInputEvent): void {
        this.setChipValue(event.value);
        event.chipInput!.clear();
    }

    setChipValue(value: string): void {
        if (value == '') throw new Error('Empty chip value is not allowed');

        value = value.trim();

        this.pathFieldControl().setValue(value);
    }

    removeField() {
        this.pathFieldControl().setValue('');
    }

    openSelect(event: Event) {
        event.stopPropagation();
        this.isOpen = !this.isOpen;
    }

    openFieldEditor(event: MouseEvent) {
        event.stopPropagation();
        this.fieldEditorDialog.open();
    }
}
