import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, inject, input, output } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UICreativePreviewComponent, UIModule } from '@bannerflow/ui';
import {
    catchError,
    combineLatest,
    filter,
    map,
    merge,
    mergeMap,
    shareReplay,
    startWith,
    Subject,
    switchMap,
    withLatestFrom
} from 'rxjs';
import { Creative } from 'src/app/models/creative.model';
import {
    CreativeDynamicField,
    DynamicField,
    FeedField,
    FeedFieldType,
    GenericCreativeFeedField
} from 'src/app/models/feed.model';
import { AccountAccessService } from 'src/app/services/api/account-access.service';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { MainFeedFieldsService } from 'src/app/services/api/main-feed-fields.service';
import { PreviewService } from 'src/app/services/api/preview.service';
import { StudioService } from 'src/app/services/api/studio.service';
import { TemplateBuilderService } from 'src/app/services/ui/template-builder.service';
import { convertFeedFieldsToCreativeDynamicFields, mapFormGroupToFeedFields } from 'src/app/utils/main-feed-helper';
import { environment } from 'src/environments/environment';
import { CreativeDetailsPipe } from '../../pipes/creative-details.pipe';
import { DynamicFormComponent } from '../../views/stepper/select-fields/dynamic-form/dynamic-form.component';
import { BottomNavigationComponent } from '../bottom-navigation/bottom-navigation.component';
import { CreativeListComponent } from '../creative-list/creative-list.component';
import { CreativePreviewComponent } from '../creative-preview/creative-preview.component';
import { CreativeSelectorComponent } from '../creative-selector/creative-selector.component';
import { PaginatedCreativePreviewComponent } from '../paginated-creative-preview/paginated-creative-preview.component';

export type DynamicFieldsData = { creativeId: string; creativeSetId: string; fields: CreativeDynamicField[] };

@Component({
    selector: 'default-creative-card',
    standalone: true,
    imports: [
        UIModule,
        CommonModule,
        MatInputModule,
        MatFormFieldModule,
        CreativeSelectorComponent,
        CreativePreviewComponent,
        BottomNavigationComponent,
        UICreativePreviewComponent,
        AsyncPipe,
        CreativeDetailsPipe,
        DynamicFormComponent,
        CreativeListComponent,
        PaginatedCreativePreviewComponent
    ],
    templateUrl: './default-creative-card.component.html',
    styleUrl: './default-creative-card.component.scss'
})
export class DefaultCreativeCardComponent {
    creative = input<Creative>();
    creativeField = input.required<GenericCreativeFeedField>();
    dynamicPropertiesChanged = output<DynamicFieldsData>();

    private readonly studioService = inject(StudioService);
    private readonly templateBuilderService = inject(TemplateBuilderService);
    private readonly accountAccessService = inject(AccountAccessService);
    private readonly previewService = inject(PreviewService);
    private readonly feedSourceService = inject(FeedSourceService);
    // TODO this component should not have a dependency on MainFeedFieldsService
    private readonly mainFeedFieldsService = inject(MainFeedFieldsService);

    private selectedCreative$ = new Subject<Creative>();
    creative$ = merge(this.selectedCreative$.asObservable(), toObservable(this.creative)).pipe(
        filter(creative => !!creative)
    );
    private dynamicFields$ = this.creative$.pipe(
        switchMap(creative =>
            this.studioService.getDynamicFieldsByCreativeId(creative.id).pipe(
                withLatestFrom(
                    this.mainFeedFieldsService.fields$.pipe(
                        map(fields => fields.filter(field => field.$type === FeedFieldType.Creative))
                    )
                ),
                map(([creativeDynamicfields, creativeFields]) => {
                    const creativeFieldMappings = creativeFields[0].mappings;
                    let result: FeedField[] = [];

                    creativeDynamicfields.forEach((field: DynamicField) => {
                        const { id, label, type } = field;
                        const feedFieldMapping = creativeFieldMappings.find(
                            mapping => mapping.dynamicPropertyId === id
                        );

                        if (feedFieldMapping) {
                            if (feedFieldMapping.$type === FeedFieldType.Path) {
                                result.push({
                                    $type: FeedFieldType.Path,
                                    dynamicPropertyId: feedFieldMapping.dynamicPropertyId,
                                    path: feedFieldMapping.path,
                                    name: label,
                                    id,
                                    dynamicContentType: field.type,
                                    required: true
                                });
                            } else if (feedFieldMapping.$type === FeedFieldType.StaticText) {
                                result.push({
                                    $type: FeedFieldType.StaticText,
                                    dynamicPropertyId: feedFieldMapping.dynamicPropertyId,
                                    id,
                                    text: feedFieldMapping.text,
                                    name: label,
                                    dynamicContentType: field.type,
                                    required: true
                                });
                            }
                        } else {
                            // If no mapping found, create a default path field
                            result.push({
                                $type: FeedFieldType.Path,
                                path: '',
                                id,
                                name: label,
                                dynamicContentType: field.type,
                                required: true
                            });
                        }
                    });

                    return result;
                })
            )
        )
    );

    dynamicFieldsFormGroup$ = this.dynamicFields$.pipe(
        map(
            dynamicFields =>
                this.templateBuilderService.buildForm(dynamicFields.filter(field => field !== undefined)).controls
                    .fields
        ),
        shareReplay(1)
    );

    private dynamicFieldsFormGroupValueChanges = this.dynamicFieldsFormGroup$.pipe(
        mergeMap(formGroup => formGroup.valueChanges),
        startWith({})
    );

    brandId$ = this.accountAccessService.user$.pipe(map(user => user.brand.id));

    dynamicPropertyData$ = combineLatest([
        this.dynamicFieldsFormGroupValueChanges,
        this.feedSourceService.feedSourceUrl$,
        this.dynamicFieldsFormGroup$,
        this.creative$
    ]).pipe(
        switchMap(([_, feedSourceUrl, dynamicFieldsFormGroup, selectedCreative]) => {
            const mappedFields = mapFormGroupToFeedFields(dynamicFieldsFormGroup).filter(
                field =>
                    (field.$type === FeedFieldType.Path && field.path !== '') ||
                    (field.$type === FeedFieldType.StaticText && field.text !== '')
            );
            this.dynamicPropertiesChanged.emit({
                creativeId: selectedCreative.id,
                creativeSetId: selectedCreative.creativeSetId,
                fields: convertFeedFieldsToCreativeDynamicFields(mappedFields)
            });
            return this.previewService
                .getPreviewData({
                    source: feedSourceUrl,
                    fields: mappedFields,
                    filters: []
                })
                .pipe(
                    map(previewData =>
                        previewData.data.map(item => {
                            return Object.entries(item.data).map(([key, value]) => {
                                const matchingField = mappedFields.find(field => field.name === key);
                                return {
                                    id: matchingField?.id ?? '',
                                    value: String(value)
                                };
                            });
                        })
                    )
                );
        }),
        catchError(() => [])
    );

    openCreative(brandId: string, creative: Creative): void {
        if (creative) {
            const url = `${environment.origins.studio}/brand/${brandId}/creativeset/${creative.creativeSetId}/editor/${creative.id}/${creative.size.id}/${creative.design.id}?version=${creative.version.id}&features=dynamic-content`;
            window.open(url, '_blank');
        }
    }

    onCreativeSelected(creative: Creative): void {
        this.selectedCreative$.next(creative);
    }
}
