@if (canConnectCreative()) {
    @if (creativeGroup().controls.creativeId.value !== '') {
        <ui-button
            type="primary"
            text="GO TO CONNECTED CREATIVE"
            class="tw-ml-[15px]"
            (click)="selectCreativeTab()" />
    } @else {
        <ui-button
            type="default"
            text="CONNECT CREATIVE"
            class="tw-ml-[15px]"
            (click)="selectCreativeTab()" />
    }
} @else {
    <p class="tw-text-sm tw-pl-5">{{ creativeText }}</p>
}
