import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UIButtonGroupOption, UIModule } from '@bannerflow/ui';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';
import { TemplateForm } from 'src/app/models/feed-form.model';
import { Template } from 'src/app/models/templates.model';
import { AccountAccessService } from 'src/app/services/api/account-access.service';
import { TemplateService } from 'src/app/services/api/template.service';

type TabOption = 'Display' | 'Social';

@Component({
    selector: 'templates',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, UIModule],
    templateUrl: './templates.component.html',
    styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent {
    templateForm = input.required<TemplateForm>();

    private templateService = inject(TemplateService);
    private accountAccessService = inject(AccountAccessService);

    private isSocialDynamicAd$: Observable<boolean> = this.accountAccessService.user$.pipe(
        map(user => {
            const userFeatures = user.account.features;
            return userFeatures.includes('SocialDynamicAdvertising');
        })
    );

    templates$: Observable<Template[]> = this.initializeTemplates();
    tabOptions$: Observable<UIButtonGroupOption[]> = this.generateTabOptions();

    selectTemplate(template: Template): void {
        this.templateForm().setValue({
            id: template.id,
            name: template.label
        });
    }

    private initializeTemplates(): Observable<Template[]> {
        return combineLatest([this.templateService.getTemplates().pipe(shareReplay(1)), this.isSocialDynamicAd$]).pipe(
            map(([templates, isSocialDynamicAd]) => {
                return [...templates].filter(template =>
                    this.isTemplateRelevant(template, 'Social', isSocialDynamicAd)
                );
            })
        );
    }

    private isTemplateRelevant(template: Template, tabOption: TabOption, isSocialDynamicAd: boolean): boolean {
        switch (tabOption) {
            case 'Display':
                return template.type === 'DisplayTemplate';
            case 'Social':
                return template.type !== 'DisplayTemplate';
            default:
                return false;
        }
    }

    private generateTabOptions(): Observable<UIButtonGroupOption[]> {
        return this.isSocialDynamicAd$.pipe(
            map(isSocialDynamicAd => {
                const createtabOption = (
                    id: string,
                    text: string,
                    value: string,
                    disabled: boolean = false
                ): UIButtonGroupOption => ({
                    id,
                    svgIcon: 'root-folder',
                    text,
                    value,
                    disabled
                });

                return [
                    // Will be re-enabled in a future release
                    // createtabOption('1', 'DISPLAY BLUEPRINTS', 'Display', isSocialDynamicAd),
                    createtabOption('2', 'SOCIAL BLUEPRINTS', 'Social', !isSocialDynamicAd)
                ];
            })
        );
    }
}
