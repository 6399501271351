import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Integration } from 'src/app/models/social-integration.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SocialAccountService {
    private http = inject(HttpClient);

    getIntegrations(): Observable<Integration[]> {
        return this.http.get<Integration[]>(`${environment.origins.socialAccountService}/api/integrations`, {
            params: { networkId: 'facebook' }
        });
    }

    getAdAccountByIntegrationId(integrationId: string) {
        return this.http.get(
            `${environment.origins.socialAccountService}/api/integrations/${integrationId}/ad-accounts"`
        );
    }
}
