@let bfcListUrl = bfcListUrl$ | async;

<header-navigation>
    <ui-svg-icon
        slot="start"
        [icon]="'log'"
        (click)="bfcListUrl && navigateToBFCList(bfcListUrl)"></ui-svg-icon>
</header-navigation>
<ui-body
    [full]="true"
    class="ui-body">
    <div class="tw-pt-3 tw-h-full">
        <mat-horizontal-stepper
            #stepper
            [linear]="true"
            (selectionChange)="onStepChange($event.selectedStep.label)">
            <ng-template
                matStepperIcon="edit"
                let-index="index">
                {{ index + 1 }}
            </ng-template>
            <mat-step
                [completed]="false"
                [label]="Stepper.ConnectYourFeed"
                data-testid="connect-your-feed">
                <connect-feed />
            </mat-step>
            <mat-step
                [completed]="false"
                [label]="Stepper.SelectTemplate">
                <templates class="tw-pb-25" />
            </mat-step>
            <mat-step
                [completed]="false"
                [label]="Stepper.SelectFields">
                <ng-template matStepContent>
                    <div class="tw-mx-20 tw-pb-25">
                        <select-fields />
                    </div>
                </ng-template>
            </mat-step>
            @if (showSelectCreativeStep$ | async) {
                <mat-step [label]="Stepper.ConnectCreative">
                    <ng-template matStepContent>
                        <div class="tw-pb-25">
                            <connect-creative />
                        </div>
                    </ng-template>
                </mat-step>
            }
            <mat-step [label]="Stepper.CustomiseYourFeed">
                <ng-template matStepContent>
                    <customise-feed />
                </ng-template>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</ui-body>
