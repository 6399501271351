import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, combineLatest, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FeedField, FeedFieldType } from 'src/app/models/feed.model';
import { Template } from 'src/app/models/templates.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MainFeedFieldsService {
    _fields$ = new BehaviorSubject<FeedField[]>([]); // TODO SHOULD BE PRIVATE
    private http = inject(HttpClient);
    fields$ = this._fields$.asObservable();

    typeCreativeFieldSelected$ = this.fields$.pipe(
        map(fields => fields.some(
            feedField => feedField.$type === FeedFieldType.Creative)
        )
    );

    setFields(fields: FeedField[]): void {
        this._fields$.next(fields);
    }

    getSuggestedFields(feedSourceUrl: Observable<string>, template: Observable<Template>): Observable<FeedField[]> {
        return combineLatest([template, feedSourceUrl]).pipe(
            distinctUntilChanged(([prevTemplate, prevFeedSourceUrl], [currTemplate, currFeedSourceUrl]) => {
                return prevTemplate.id === currTemplate.id && prevFeedSourceUrl === currFeedSourceUrl;
            }),
            switchMap(([template, feedSourceUrl]) => {
                const endpoint = template.useAI
                    ? `${environment.origins.feedsSupremeCommander}/api/MainFeeds/blueprintWithAiSuggestions`
                    : `${environment.origins.feedsSupremeCommander}/api/MainFeeds/blueprint`;

                const payload = template.useAI
                    ? { templateId: template.id, sourceFeedUrl: feedSourceUrl }
                    : { templateId: template.id };

                return this.http.post<FeedField[]>(endpoint, payload);
            })
        );
    }
}
