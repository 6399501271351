@if (template.type === 'DisplayTemplate') {
    <ui-button
        text="Add Field"
        type="default"
        svgIcon="add"
        (click)="addDisplayField()">
    </ui-button>
}

@if (template.type === 'MetaTemplate') {
    <div
        class="add-fields-wrapper"
        #addFieldWrapper>
        @if (socialMediaOptionalFields$ | async; as socialMediaOptionalFields) {
            <mat-form-field class="drop-down">
                <mat-label>Add field</mat-label>
                <mat-select #select>
                    @for (field of socialMediaOptionalFields.optionalFields; track field) {
                        <mat-option [value]="field.label">{{ field.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <div class="action-buttons">
                <ui-button
                    text="Add Field"
                    type="default"
                    svgIcon="add"
                    [disabled]="!select.value"
                    (click)="addSDAField(select.value)">
                    >
                </ui-button>
            </div>
        }
    </div>
}
