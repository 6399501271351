<div class="tw-flex tw-items-center tw-gap-x-10 tw-p-6 tw-shadow-md tw-rounded-md">
    @for (summary of operationsSummary; track summary) {
        <div class="tw-flex tw-items-center tw-gap-x-2">
            <ui-svg-icon
                class="tw-text-sm"
                [icon]="summary.type === OperationsType.Filter ? 'filter-2' : 'smiley'"></ui-svg-icon>
            <p class="tw-text-sm">{{ summary.label }}</p>
        </div>
        @for (sentence of summary.summary; track sentence) {
            <p
                class="tw-text-sm"
                [innerHTML]="sentence"></p>
        }
    }
</div>
