import { FormArray, FormGroup, Validators } from '@angular/forms';
import {
    CreativeDynamicField,
    FeedField,
    FeedFieldType,
    FeedUpdateInterval,
    GenericCreativeFeedField,
    GenericPathFeedField,
    GenericStaticTextFeedField
} from '../models/feed.model';
import { GenericFeedFieldForm } from '../services/ui/template-builder.service';
import { CreativesData, Creative } from '../models/creative.model';
import { FilterGroupForm, FilterGroups, FilterGroup, Filter } from '../models/filters.model';

export function setFieldIds(fields: FeedField[]): FeedField[] {
    fields.forEach(field => {
        if (field.name && field.id == null) {
            field.id = field.name.replace(/\s/g, '');
        }
    });

    return fields;
}

export function findFieldById(fields: FeedField[], id: string): FeedField {
    for (let i = 0; i < fields.length; i++) {
        const currentField = fields[i];
        if (currentField.id === id) {
            return currentField;
        }
    }

    throw new Error(`Field with id ${id} not found`);
}

export function mapFormGroupToFeedFields(formGroup: FormArray<FormGroup<GenericFeedFieldForm>>): FeedField[] {
    return formGroup.controls.map((fieldGroup: FormGroup<GenericFeedFieldForm>) => {
        const type = fieldGroup.controls.type.value;
        const name = fieldGroup.controls.name.value;
        const id = fieldGroup.controls.id.value;
        const data = Array.isArray(fieldGroup.controls.data.value)
            ? fieldGroup.controls.data.value.join('.')
            : fieldGroup.controls.data.value;
        const required = fieldGroup.controls.data.hasValidator(Validators.required);

        if (type === FeedFieldType.StaticText) {
            return {
                $type: type,
                name: name,
                required: required,
                text: data,
                id: id
            };
        } else if (type === FeedFieldType.Creative) {
            return {
                $type: type,
                name: name,
                required: !fieldGroup.controls.removeable.value,
                creativeSetId: '',
                creativeId: '',
                mappings: [],
                id: id
            };
        } else {
            return {
                $type: FeedFieldType.Path,
                name: name,
                required: required,
                path: data,
                children: [],
                id: id
            };
        }
    });
}

export function convertFeedFieldsToCreativeDynamicFields(feedFields: FeedField[]): CreativeDynamicField[] {
    return feedFields
        .filter(
            (field): field is GenericPathFeedField | GenericStaticTextFeedField =>
                field.$type === FeedFieldType.Path || field.$type === FeedFieldType.StaticText
        )
        .map((field): CreativeDynamicField => {
            const baseField = {
                dynamicPropertyId: field.id
            };

            if (field.$type === FeedFieldType.Path) {
                return {
                    $type: 'Path',
                    path: field.path,
                    ...baseField,
                };
            } else {
                return {
                    $type: 'StaticText',
                    text: field.text,
                    ...baseField,
                };
            }
        });
}

export function createCreativeFromCreativesData(
    creativeSetData: CreativesData,
    creativeField: GenericCreativeFeedField
): Creative {
    const selectedCreative = creativeSetData.creatives.find(creative => creative.id === creativeField.creativeId);
    if (!selectedCreative) {
        throw new Error(`Creative with ID ${creativeField.creativeId} not found.`);
    }

    const creativeVersion = creativeSetData.versions.find(version => version.id === selectedCreative.version?.id) || {
        id: '',
        name: ''
    };

    const size = creativeSetData.sizes.find(size => size.id === selectedCreative.size?.id) || {
        id: '',
        width: 0,
        height: 0
    };

    return {
        ...selectedCreative,
        creativeSetId: creativeField.creativeSetId,
        creativeSetName: creativeSetData.name,
        size,
        version: creativeVersion
    };
}

export function readableUpdateIntervals(): { value: FeedUpdateInterval; label: string }[] {
    return [
        { value: FeedUpdateInterval.None, label: 'Manual' },
        { value: FeedUpdateInterval.TwentyMinute, label: 'Every 20 minutes' },
        { value: FeedUpdateInterval.ThirtyMinutes, label: 'Every 30 minutes' },
        { value: FeedUpdateInterval.OneHour, label: 'Every hour' },
        { value: FeedUpdateInterval.ThreeHours, label: 'Every 3 hours' },
        { value: FeedUpdateInterval.SixHours, label: 'Every 6 hours' },
        { value: FeedUpdateInterval.TwelveHours, label: 'Every 12 hours' },
        { value: FeedUpdateInterval.EveryDay, label: 'Every day' },
        { value: FeedUpdateInterval.EveryWeek, label: 'Every week' }
    ];
}

export function transformFilterFormToFilterGroups(
    filterForm: FormGroup<{ arrayGroups: FormArray<FormGroup<FilterGroupForm>> }>
): FilterGroup[] {
    const arrayGroupsFormArray = filterForm.controls.arrayGroups;

    return arrayGroupsFormArray.controls.map(filterGroupControl => {
        const label = filterGroupControl.controls.label.value;
        const arrayGroup = filterGroupControl.controls.arrayGroup.value;
        const filterType = filterGroupControl.controls.filterType.value;
        const filtersFormArray = filterGroupControl.controls.filters;

        const filters: Filter[] = filtersFormArray.controls.map(filterControl => ({
            field: filterControl.controls.field.value[0], // Assuming field is string[], taking first value
            operator: filterControl.controls.operator.value,
            arguments: filterControl.controls.arguments.value
        }));

        const filterGroup: FilterGroup = {
            arrayGroup,
            filterType,
            filters
        };

        if (label) {
            filterGroup.label = label;
        }

        return filterGroup;
    });
}

// Example usage:
// const result = transformFilterFormToFilterGroups(filterForm);
