import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, map, shareReplay, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeedNode } from '../../models/feed.model';

export interface ResponseSchemaField {
    fieldName: string;
    path: string;
    descendants: ResponseSchemaField[];
    previewData: string[];
}

@Injectable({
    providedIn: 'root'
})
export class FeedSourceService {
    private _feedSourceUrl$ = new BehaviorSubject<string>('');
    feedSourceUrl$ = this._feedSourceUrl$.asObservable();
    private _feedSourceFields$: Observable<FeedNode[]>;

    private http = inject(HttpClient);

    getFeedSourceFields(): Observable<FeedNode[]> {
        if (!this._feedSourceFields$) {
            this._feedSourceFields$ = this._feedSourceUrl$.pipe(
                switchMap(url =>
                    this.http.post<ResponseSchemaField[]>(
                        `${environment.origins.feedsFearsomeProcessor}/GenerateSchema`,
                        {
                            source: url
                        }
                    )
                ),
                map(response => this.convertToFeedNode(response)),
                shareReplay(1)
            );
        }
        return this._feedSourceFields$;
    }

    setFeedSourceUrl(url: string) {
        this._feedSourceUrl$.next(url);
    }

    private convertToFeedNode(schemaFields: ResponseSchemaField[]): FeedNode[] {
        if (!schemaFields) {
            return [];
        }

        return schemaFields.map(field => {
            const feedNode: FeedNode = {
                name: field.fieldName,
                path: field.path,
                isArray: field.descendants && field.descendants.length > 0,
                values: field.previewData,
                children:
                    field.descendants && field.descendants.length > 0
                        ? this.convertToFeedNode(field.descendants)
                        : undefined
            };
            return feedNode;
        });
    }
}
