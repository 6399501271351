import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, catchError, of } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { Template, TemplateDetails } from '../../models/templates.model';
@Injectable({
    providedIn: 'root'
})
export class TemplateService {
    private http = inject(HttpClient);
    private _selectedTemplate = new BehaviorSubject<Template>({} as Template);
    selectedTemplate$: Observable<Template> = this._selectedTemplate.asObservable();

    getTemplates(): Observable<Template[]> {
        return this.http.get<Template[]>(`${env.origins.feedsSupremeCommander}/api/Templates`).pipe(
            catchError(error => {
                console.error('Failed to load templates', error);
                return of([]);
            })
        );
    }

    getTemplateVerticalById(id: string): Observable<TemplateDetails> {
        return this.http
            .get<TemplateDetails>(`${env.origins.feedsSupremeCommander}/api/Templates/${id}`);
    }

    selectTemplate(template: Template) {
        this._selectedTemplate.next(template);
    }
}
