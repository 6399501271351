import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { CreativeDynamicField } from 'src/app/models/feed.model';
import { environment } from 'src/environments/environment';

interface UpdateMainFeedBlueprintCreativeFieldRequest {
    creativeSetId: string;
    creativeId: string;
    fields: CreativeDynamicField[];
    mainFeedId: string;
    fieldId: string;
}

@Injectable({
    providedIn: 'root'
})
export class DynamicFieldsService {
    private http = inject(HttpClient);

    updateCreativeField(request: UpdateMainFeedBlueprintCreativeFieldRequest): Observable<HttpResponse<void>> {
        const body = {
            creativeSetId: request.creativeSetId,
            creativeId: request.creativeId,
            fields: request.fields.map(({ $type, ...rest }) => ({ $type: $type, ...rest }))
        };
        return this.http
            .put<HttpResponse<void>>(`${environment.origins.feedsSupremeCommander}/api/mainfeeds/${request.mainFeedId}/blueprint/creativeFields/${request.fieldId}`, body)
            .pipe(
                catchError(error => {
                    return throwError(() => error);
                })
            );
    }
}
