import { AsyncPipe } from '@angular/common';
import { Component, computed, effect, input, signal, ViewChild } from '@angular/core';
import { CREATIVE_PREVIEW_HOST, IDynamicPropertyData, UICreativePreviewComponent, UIModule } from '@bannerflow/ui';
import { Creative } from 'src/app/models/creative.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'paginated-creative-preview',
    standalone: true,
    imports: [UIModule, UICreativePreviewComponent, AsyncPipe],
    providers: [{ provide: CREATIVE_PREVIEW_HOST, useValue: environment.origins.creativePreviewUrl }],
    templateUrl: './paginated-creative-preview.component.html',
    styleUrl: './paginated-creative-preview.component.scss'
})
export class PaginatedCreativePreviewComponent {
    @ViewChild(UICreativePreviewComponent) creativePreview: UICreativePreviewComponent;

    creative = input.required<Creative>();
    dynamicPropertyData = input.required<IDynamicPropertyData[][]>();

    currentPage = signal(1);
    currentPreviewData = computed(() => {
        return this.dynamicPropertyData()[this.currentPage() - 1];
    });

    firstRender = signal(true);

    constructor() {
        effect(
            () => {
                // TODO remove once ui-creative-preview will emit an event when it's ready
                if (this.firstRender()) {
                    setTimeout(() => {
                        this.creativePreview.renderDynamicProperties(this.currentPreviewData());
                    }, 4000);
                    this.firstRender.set(false);
                } else {
                    this.creativePreview.renderDynamicProperties(this.currentPreviewData());
                }
            },
            { allowSignalWrites: true }
        );

        effect(
            () => {
                // If the creative changes, reset to page 1
                this.creative();
                this.currentPage.set(1);
            },
            { allowSignalWrites: true }
        );
    }

    previous(): void {
        if (this.currentPage() > 1) {
            this.currentPage.update(page => page - 1);
        }
    }

    next(): void {
        if (this.currentPage() < this.dynamicPropertyData().length) {
            this.currentPage.update(page => page + 1);
        }
    }

    pageChange(page: number): void {
        if (page >= 1 && page <= this.dynamicPropertyData().length) {
            this.currentPage.update(() => page);
        }
    }
}
