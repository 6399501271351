export enum ListItemType {
    Folder = 'Folder',
    CreativeSet = 'CreativeSet'
}
export interface CreativeSetListItem {
    id: string;
    externalId?: string;
    name: string;
    creativesCount: number;
    createdAt: string;
    createdBy: string;
    modifiedAt: string;
    modifiedBy: string;
    status: string;
    locations?: Location[];
    type: ListItemType;
    thumb?: string;
}

export interface CreativeSetTableData {
    items: CreativeSetListItem[];
    path: Path[];
    totalItemsCount: number;
}

export interface Path {
    id: string;
    name: string;
}
