@if (templateData$ | async; as templateData) {
    <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
        <h2>Now
            <div class="tw-inline" (dblclick)="clickLoadDummyData()">
                match
            </div>
            your fields with the template fields</h2>
        <template-fields
            [template]="templateData.template"
            [formGroup]="templateData.formGroup"
            [feedSourceUrl]="templateData.feedSourceUrl"
            (updateTemplateLabel)="updateTemplateLabel($event)" />
    </div>
    <bottom-navigation>
        <div class="tw-flex tw-gap-6 tw-pr-5">
            @if (templateData.formGroup.invalid) {
                <p class="tw-text-ui-warning">
                    <ui-svg-icon
                        [icon]="'warning-l-negative'"
                        class="info-fields"></ui-svg-icon
                    >Connect all fields before proceeding
                </p>
            }
            @if (mainFeedId$ | async; as mainFeedId) {
                <ui-button
                    type="primary"
                    [loading]="(loading$ | async)!"
                    text="UPDATE FEED"
                    (click)="
                        updateBlueprint(templateData.template.label, mainFeedId, templateData.formGroup)
                    "></ui-button>
            } @else {
                <ui-button
                    type="primary"
                    [loading]="(loading$ | async)!"
                    text="NEXT"
                    [disabled]="templateData.formGroup.invalid"
                    (click)="
                        saveBlueprint(templateData.template.label, templateData.template.id, templateData.formGroup)
                    " />
            }
        </div>
    </bottom-navigation>
} 
<ui-dialog
    #previewDialog="ui-dialog"
    [config]="{ headerText: 'TABLE VIEW', maxWidth: 898, maxHeight: 531 }">
    <ng-template ui-dialog-template>
        <preview-table></preview-table>
    </ng-template>
</ui-dialog>
