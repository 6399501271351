import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UIModule, UINotificationService, UITabsComponent } from '@bannerflow/ui';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    EMPTY,
    filter,
    finalize,
    forkJoin,
    map,
    Observable,
    of,
    shareReplay,
    switchMap,
    take,
    tap,
    throwError,
    withLatestFrom
} from 'rxjs';
import { FeedFieldType, FeedUpdateInterval, MainFeed } from 'src/app/models/feed.model';
import { Template } from 'src/app/models/templates.model';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { MainFilterService } from 'src/app/services/api/main-filter.service';
import { StudioService } from 'src/app/services/api/studio.service';
import { TemplateService } from 'src/app/services/api/template.service';
import { DraggableBottomSheetService } from 'src/app/services/ui/draggable-bottom-sheet.service';
import { TabSelectionService } from 'src/app/services/ui/tab-selection.service';
import { GenericFeedFieldFormGroup, TemplateBuilderService } from 'src/app/services/ui/template-builder.service';
import { FiltersComponent } from 'src/app/shared/filters/filters.component';
import { createCreativeFromCreativesData, mapFormGroupToFeedFields } from 'src/app/utils/main-feed-helper';
import {
    DefaultCreativeCardComponent,
    DynamicFieldsData
} from '../../../shared/default-creative-card/default-creative-card.component';
import { HeaderNavigationComponent } from '../../../shared/header-navigation/header-navigation.component';
import { BottomSheetPreviewComponent } from '../../stepper/customise-feed/bottom-sheet-preview/bottom-sheet-preview.component';
import { TemplateFieldsComponent } from '../../stepper/select-fields/template-fields/template-fields.component';
import { DynamicFieldsService } from 'src/app/services/api/dynamic-fields.service';
import { FilterGroup, FilterGroups } from 'src/app/models/filters.model';
import { MainFeedFieldsService } from 'src/app/services/api/main-feed-fields.service';

@Component({
    selector: 'editable-feed',
    standalone: true,
    imports: [
        UIModule,
        CommonModule,
        TemplateFieldsComponent,
        RouterLink,
        HeaderNavigationComponent,
        DefaultCreativeCardComponent,
        FiltersComponent
    ],
    templateUrl: './editable-feed.component.html',
    styleUrl: './editable-feed.component.scss'
})
export class EditableFeedComponent implements OnDestroy {
    @ViewChild(UITabsComponent) uiTabsComponent: UITabsComponent;
    private mainFeedService = inject(MainFeedService);
    private activatedRoute = inject(ActivatedRoute);
    private mainFilterService = inject(MainFilterService);
    private route = inject(Router);
    private feedSourceService = inject(FeedSourceService);
    private templateService = inject(TemplateService);
    private templateBuilderService = inject(TemplateBuilderService);
    private uiNotificationService = inject(UINotificationService);
    private studioService = inject(StudioService);
    private draggableBottomSheetService = inject(DraggableBottomSheetService);
    private tabSelectionService = inject(TabSelectionService);
    private mainFeedFieldsService = inject(MainFeedFieldsService);

    loading$ = new BehaviorSubject<boolean>(false);
    private _templateLabel$ = new BehaviorSubject<string>('');
    templateLabel$ = this._templateLabel$.asObservable();
    private _filters$ = new BehaviorSubject<FilterGroup[]>([]);
    private _creativeSetDynamicData$ = new BehaviorSubject<DynamicFieldsData>({
        creativeId: '',
        creativeSetId: '',
        fields: []
    });

    toggleCreativeTab = false;

    mainFeedData$ = this.fetchMainFeedByRouteId().pipe(shareReplay(1));

    fieldsTabData$ = this.mainFeedData$.pipe(
        switchMap(mainFeedData => {
            this.feedSourceService.setFeedSourceUrl(mainFeedData.sourceUrl);
            this._templateLabel$.next(mainFeedData.name);

            return combineLatest([
                this.templateService.getTemplateVerticalById(mainFeedData.templateId!),
                this.templateLabel$
            ]).pipe(
                map(([templateData, updatedLabel]) => ({
                    template: {
                        id: mainFeedData.templateId,
                        label: updatedLabel,
                        type: templateData.type,
                        useAI: false
                    },
                    feedId: mainFeedData.id,
                    sourceUrl: mainFeedData.sourceUrl,
                    formGroup: this.templateBuilderService.buildForm(mainFeedData.blueprint.fields),
                    updateInterval: mainFeedData.updateInterval
                }))
            );
        })
    );

    creativeTabData$ = this.mainFeedData$.pipe(
        map(mainFeedData => {
            const creativeField = mainFeedData.blueprint.fields.find(field => field.$type === FeedFieldType.Creative);

            if (!creativeField) {
                throw new Error('Creative field not found');
            }

            return creativeField;
        }),
        switchMap(creativeField => {
            // If no creativeSetId exists, return just the field with undefined creative
            // so the user gets the option to select a creative
            if (!creativeField.creativeSetId) {
                return of({
                    creative: undefined,
                    creativeField
                });
            }

            // Otherwise, proceed with getting creatives and versions
            return this.studioService.getCreativesAndVersions(creativeField.creativeSetId).pipe(
                map(creativesData => ({
                    creative: createCreativeFromCreativesData(creativesData, creativeField),
                    creativeField
                }))
            );
        })
    );

    operationsTabData$ = this.mainFeedData$.pipe(
        map(mainFeedData => {
            if (mainFeedData.filters.filterGroups.length > 0) {
                this.mainFilterService.setFilters(mainFeedData.filters);
            }

            return mainFeedData.filters;
        })
    );

    ngOnInit(): void {
        this.tabSelectionService.selectTab$.subscribe((shouldSelectTab: boolean) => {
            if (shouldSelectTab && this.uiTabsComponent) {
                const creativesTab = this.uiTabsComponent.tabs.find(tab => tab.name === 'Creatives');
                if (creativesTab) {
                    this.uiTabsComponent.selectTab(creativesTab);
                }
            }
        });
    }

    // TODO there is a bug in ui-bannerflow tabs making creative tab initialize even tho template is not loaded
    creativeTabSelected(selected: boolean): void {
        this.toggleCreativeTab = selected;
    }

    updateMainFeed(
        templateName: string,
        feedId: string,
        formGroup: GenericFeedFieldFormGroup,
        sourceUrl: string,
        updateInterval: FeedUpdateInterval
    ): void {
        this.loading$.next(true);

        // Track status of each operation
        const operationStatus = {
            mainFeed: { completed: false, success: false, message: '' },
            filters: { completed: false, success: false, message: '' }
        };

        const showConsolidatedNotification = () => {
            const successful = Object.entries(operationStatus)
                .filter(([_, status]) => status.success)
                .map(([key, _]) => key);

            const failed = Object.entries(operationStatus)
                .filter(([_, status]) => !status.success && status.completed)
                .map(([_, status]) => status.message);

            let message = '';
            if (successful.length > 0) {
                message += `Successfully saved: ${successful.join(', ')}. `;
            }
            if (failed.filter(msg => msg !== '').length > 0) {
                message += `Failed: ${failed.join(', ')}`;
            }

            this.uiNotificationService.open(message, {
                type: successful.length > 0 ? 'success' : 'error',
                placement: 'top'
            });
        };

        const checkCompletion = () => {
            const allCompleted = Object.values(operationStatus).every(status => status.completed);

            if (allCompleted) {
                this.loading$.next(false);
                showConsolidatedNotification();
            }
        };

        // Handle main feed update independently
        this.mainFeedFieldsService.fields$
            .pipe(
                withLatestFrom(this._creativeSetDynamicData$),
                switchMap(([fields, mappings]) => {
                    mappings.fields;

                    const newFields = fields.map(field => {
                        if (field.$type === FeedFieldType.Creative && field.creativeId === mappings.creativeId) {
                            field.mappings = mappings.fields;
                            return field;
                        } else {
                            return field;
                        }
                    });

                    return this.mainFeedService
                        .updateMainFeed({
                            blueprint: { fields: newFields },
                            id: feedId,
                            name: templateName,
                            sourceUrl: sourceUrl,
                            updateInterval
                        })
                        .pipe(
                            take(1),
                            finalize(() => {
                                operationStatus.mainFeed.completed = true;
                                checkCompletion();
                            })
                        );
                })
            )
            .subscribe({
                next: () => {
                    operationStatus.mainFeed.success = true;
                    this.route.navigate(['../'], { relativeTo: this.activatedRoute });
                },
                error: () => {
                    operationStatus.mainFeed.success = false;
                    operationStatus.mainFeed.message = 'main feed update';
                }
            });

        // Handle filters update independently
        this._filters$
            .pipe(
                filter(filters => {
                    const shouldSaveFilters = filters.length > 0;
                    if (!shouldSaveFilters) {
                        operationStatus.filters.completed = true;
                        operationStatus.filters.success = true;
                        return false;
                    }
                    return filters.length > 0;
                }),
                switchMap(filters =>
                    this.mainFilterService.saveMainFilter(feedId, { filterGroups: filters }).pipe(
                        take(1),
                        finalize(() => {
                            operationStatus.filters.completed = true;
                            checkCompletion();
                        })
                    )
                )
            )
            .subscribe({
                next: () => {
                    operationStatus.filters.success = true;
                },
                error: () => {
                    operationStatus.filters.success = false;
                    operationStatus.filters.message = 'filters';
                }
            });
    }

    updateTemplateLabel(template: Template) {
        this._templateLabel$.next(template.label);
    }

    operationsTabSelected(selected: boolean): void {
        if (selected) {
            const componentPortal = new ComponentPortal(BottomSheetPreviewComponent);
            this.draggableBottomSheetService.createBottomSheet(componentPortal);
        } else {
            this.draggableBottomSheetService.removeBottomSheet();
        }
    }

    private fetchMainFeedByRouteId(): Observable<Required<MainFeed>> {
        return this.activatedRoute.params.pipe(
            switchMap(params => this.mainFeedService.getMainFeedById(params['id'])),
            tap(mainFeed => this.mainFeedFieldsService.setFields(mainFeed.blueprint.fields))
        );
    }

    setFilters(filterGroups: FilterGroup[]): void {
        this._filters$.next(filterGroups);
    }

    setCreative(event: DynamicFieldsData): void {
        this._creativeSetDynamicData$.next(event);
    }

    ngOnDestroy(): void {
        this.draggableBottomSheetService.removeBottomSheet();
    }
}
