<div class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-max-w-7xl">
    <div class="tw-grid tw-grid-cols-6 tw-mb-8">
        <ui-button
            class="tw-w-fit button-template"
            type="discrete"
            [border]="false"
            text="EDIT SOURCE FEED"
            (click)="editSourceFeed()"
            svgIcon="direction-left" />
        @if (tabOptions$ | async; as tabOptions) {
            <ui-button-group
                class="tw-w-full tw-max-w-lg tw-col-start-2 tw-col-span-4 tw-mx-auto button-group-template"
                [options]="tabOptions"
                [primarySelectionStyle]="true"
                [value]="tabOptions[0].value"
                (valueChange)="updateTabOption($event)" />
        }
    </div>
    @if (templates$ | async; as templates) {
        <div class="tw-flex tw-flex-row tw-gap-12">
            <div class="tw-w-[712px] tw-grid tw-grid-cols-4 tw-gap-6">
                @for (template of templates.templates; track template; let i = $index) {
                    <div
                        class="tw-border tw-border-solid tw-border-ui-grey-86 tw-rounded tw-h-[218px] tw-w-40 tw-p-2 tw-bg-ui-white"
                        (click)="selectTemplate(template, templates.tabOption)"
                        [ngClass]="{ clicked: templates.selectedTemplate.id === template.id }">
                        <div class="tw-h-35 tw-w-35 tw-bg-ui-waterfall"></div>
                        <div class="tw-flex tw-flex-col tw-w-35 tw-h-5 tw-mt-2">
                            <p class="tw-text-black tw-text-sm tw-font-bold tw-m-0">{{ template.label }}</p>
                            <p class="tw-text-black tw-text-xs tw-m-0">Perfect for ads with dynamic ads</p>
                        </div>
                    </div>
                }
            </div>
        </div>
        <bottom-navigation class="tw-flex tw-justify-end">
            <div class="tw-flex tw-flex-row tw-gap-6 tw-pr-5">
                <ui-button
                    [border]="false"
                    text="SET UP MANUALLY"
                    icon="folder"
                    (click)="useThisTemplate(templates.selectedTemplate)"></ui-button>
                <!-- temporary commented out until BE is fixed -->
                <!-- <ui-button
                    type="primary"
                    text="SET UP WITH AI"
                    icon="folder"
                    (click)="useThisTemplateWithAI(templates.selectedTemplate)"></ui-button> -->
            </div>
        </bottom-navigation>
    }
</div>
