import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PullRequestService {
    private http = inject(HttpClient);

    getPullRequests(): Observable<string[]> {
        return this.http.get<string[]>('https://sandbox-feeds.bannerflow.com/deployment-slots.json');
    }
}
