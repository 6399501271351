import { Component, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { NS_CONFIG_TOKEN } from '@bannerflow/notification';
import { SentinelService } from '@bannerflow/sentinel';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    /**
     * Injects the Sentinel service
     * Do not remove it, it is used to initialize the Sentinel service
     * otherwise new relic will not work, once we create a globla error handler
     * sentinel will be injected there and this can most likely be removed
     */
    private sentinelService = inject(SentinelService);
    private authService = inject(AuthService);
    private nsConfig = inject(NS_CONFIG_TOKEN);
    private user = toSignal(this.authService.user$);

    constructor() {
        effect(
            () => {
                const user = this.user();
                if (user) {
                    this.authService.getAccessTokenSilently({ cacheMode: 'on' }).subscribe(accessToken => {
                        this.nsConfig.accessToken$.next(accessToken);
                    });
                }
            },
            {
                allowSignalWrites: true
            }
        );
    }
}
