import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { UIModule } from '@bannerflow/ui';
import { FeedFieldType } from 'src/app/models/feed.model';
import { MainFeedFieldsService } from 'src/app/services/api/main-feed-fields.service';

interface fieldType {
    value: FeedFieldType;
    viewValue: string;
}

@Component({
    selector: 'select-type',
    standalone: true,
    imports: [CommonModule, UIModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule],
    templateUrl: './select-type.component.html',
    styleUrl: './select-type.component.scss'
})
export class SelectTypeComponent {
    @Input({ required: true }) fieldFormControlType: FormControl;
    @Input({ required: true }) fieldFormControlData: FormControl;
    @Input() isDynamicFieldContent: boolean;

    mainFeedFieldsService = inject(MainFeedFieldsService);
    fieldTypes: fieldType[] = [
        { value: FeedFieldType.Path, viewValue: 'Feed' },
        { value: FeedFieldType.StaticText, viewValue: 'Static Text' },
        { value: FeedFieldType.Creative, viewValue: 'Creative' }
    ];

    ngOnInit(): void {
        this.fieldTypes = this.filteredFieldTypes();
    }

    filteredFieldTypes(): fieldType[] {
        if (this.isDynamicFieldContent) {
            return this.fieldTypes.filter(field => field.value !== FeedFieldType.Creative);
        }
        return this.fieldTypes;
    }

    selectionChange(type: FeedFieldType): void {
        this.fieldFormControlType.setValue(type);
        this.fieldFormControlData.patchValue('');
    }
}
