import { Component } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { SandboxPrSlotsComponent } from '../sandbox-pr-slots/sandbox-pr-slots.component';
import { RouterLink } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'header-navigation',
  standalone: true,
  imports: [UIModule, SandboxPrSlotsComponent, RouterLink],
  templateUrl: './header-navigation.component.html',
  styleUrl: './header-navigation.component.scss'
})
export class HeaderNavigationComponent {
  environment = environment;

}
