import { HttpInterceptorFn } from '@angular/common/http';
import { AccountAccessService } from '../services/api/account-access.service';
import { inject } from '@angular/core';
import { switchMap } from 'rxjs';

export const bfHeaderInterceptor: HttpInterceptorFn = (req, next) => {
    const accountAccessService = inject(AccountAccessService);

    if (req.url.includes('/users/current') || req.url.includes('/user/defaults')) {
        return next(req);
    }

    return accountAccessService.user$.pipe(
        switchMap(user => {
            const brandId = user.brand.id;

            const bfBrandReq = req.clone({
                headers: req.headers.set('bf-brand-id', brandId)
            });

            return next(bfBrandReq);
        })
    );
};
