@for (control of creativeMappingsForm().controls; track control; let i = $index) {
    <div
        class="tw-w-full tw-grid tw-grid-cols-2 tw-auto-rows-auto tw-py-4 tw-gap-2"
        [class.divider]="i !== (creativeMappingsForm().controls | keyvalue).length - 1">
        <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-pr-5">
            <p class="tw-text-sm">{{ control.controls.dynamicPropertyLabel.value }}</p>
            <mat-icon
                class="field-icon"
                [class.tw-text-ui-primary]="control.controls.data.value !== ''"
                >link</mat-icon
            >
        </div>
        <div class="tw-flex tw-flex-row tw-justify-between tw-gap-3">
            <select-type
                class="tw-w-34"
                [isDynamicFieldContent]="true"
                [bluePrintFieldTypeControl]="control.controls.$type" />
            @switch (control.controls.$type.value) {
                @case (FeedFieldType.Path) {
                    <path-field
                        class="tw-w-full"
                        [pathFieldControl]="control.controls.data"
                        [name]="control.controls.dynamicPropertyLabel.value" />
                }
                @case (FeedFieldType.StaticText) {
                    <static-text-field
                        class="tw-flex tw-items-center tw-w-full"
                        [textFormControl]="control.controls.data"
                        [name]="control.controls.dynamicPropertyLabel.value" />
                }
            }
        </div>
    </div>
}
