import { Component, inject, input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { CreativeMetaData } from 'src/app/models/feed-form.model';
import { TabSelectionService } from 'src/app/services/ui/tab-selection.service';

@Component({
    selector: 'creative-field',
    standalone: true,
    imports: [UIModule],
    templateUrl: './creative-field.component.html',
    styleUrl: './creative-field.component.scss'
})
export class CreativeFieldComponent {
    creativeGroup = input.required<CreativeMetaData>();
    canConnectCreative = input.required<boolean>();

    tabSelectionService = inject(TabSelectionService);
    creativeText = 'The creative can be connected in the next step';

    selectCreativeTab(): void {
        this.tabSelectionService.selectTab(true);
    }
}
