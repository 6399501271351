import { AppEnvironment } from './environment.model';

export const environment: AppEnvironment = {
    stage: 'sandbox',
    origins: {
        feedsFearsomeProcessor: 'https://sandbox-api.bannerflow.com/feeds-fearsome-processor',
        feedsSupremeCommander: 'https://sandbox-api.bannerflow.com/feeds-supreme-commander',
        feedsSocializingMaster: 'https://sandbox-api.bannerflow.com/feeds-socializing-master',
        acg: 'https://sandbox-api.bannerflow.com/acg',
        feedsDeliveryMaster: 'https://sandbox-api.bannerflow.com/feeds-delivery-master',
        listService: 'https://sandbox-api.bannerflow.com/list-service',
        sapi: 'https://sandbox-api.bannerflow.com/studio/api',
        accountAccessService: 'https://sandbox-api.bannerflow.com/account-access',
        brandManager: 'https://sandbox-api.bannerflow.com/brand-manager',
        socialAccountService: 'https://sandbox-sas.bannerflow.com',
        bfc: 'https://sandbox-home.bannerflow.com',
        creativePreviewUrl: 'https://sandbox-api.bannerflow.com/preview',
        studio: 'https://sandbox-studio.bannerflow.com'

    },
    auth: {
        clientId: 'pD2aOQUrR5iPSLua1T9PqWIRvsHZbVgb',
        domain: 'https://sandbox-login.bannerflow.com',
        audience: 'https://bannerflow.com/resources/'
    }
};
