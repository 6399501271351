<ui-creative-preview
    class="tw-border tw-border-solid tw-border-ui-waterfall tw-max-w-[400px] tw-max-h-[400px] tw-overflow-hidden"
    [creativeset]="creative().creativeSetId"
    [creative]="creative().id"></ui-creative-preview>

@if (dynamicPropertyData().length > 0) {
    <div class="tw-mt-2 tw-flex tw-justify-between">
        <p class="tw-text-sm tw-text-gray-600 tw-mb-1">Preview other values</p>
        <div class="tw-flex tw-items-center tw-gap-0">
            <ui-button
                (click)="previous()"
                ui-theme="minimal"
                [svgIcon]="'arrow-left'"
                class="tw-bg-gray-100"
                [disabled]="currentPage() === 1">
            </ui-button>
            <ui-number-input
                class="tw-w-8 tw-text-center tw-mx-1"
                ui-theme="minimal"
                [min]="1"
                [max]="dynamicPropertyData().length"
                (valueChange)="pageChange($event)"
                [value]="currentPage()" />
            <span class="tw-text-gray-600 tw-mr-1">/{{ dynamicPropertyData().length }}</span>
            <ui-button
                (click)="next()"
                ui-theme="minimal"
                [svgIcon]="'arrow-right'"
                class="tw-bg-gray-100"
                [disabled]="currentPage() === dynamicPropertyData().length">
            </ui-button>
        </div>
    </div>
}
