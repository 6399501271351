import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { Observable } from 'rxjs';
import { PullRequestService } from 'src/app/services/api/pull-request.service';

@Component({
    selector: 'sandbox-pr-slots',
    standalone: true,
    imports: [CommonModule, UIModule],
    templateUrl: './sandbox-pr-slots.component.html',
    styleUrl: './sandbox-pr-slots.component.scss'
})
export class SandboxPrSlotsComponent implements OnInit {
    selectedBranch = '';
    pullRequests$: Observable<string[]>;

    private pullRequestService = inject(PullRequestService);

    constructor() {
        const url = new URL(window.location.href);
        this.selectedBranch = url.searchParams.get('branch') || 'Main';
    }

    ngOnInit() {
        this.pullRequests$ = this.pullRequestService.getPullRequests();
    }

    selectedChanged(branch: string): void {
        this.selectedBranch = branch;
        const url = new URL(window.location.href);
        if (branch && branch !== 'Main') {
            url.searchParams.set('branch', branch);
        } else {
            url.searchParams.delete('branch');
        }
        window.location.href = url.href;
    }
}
