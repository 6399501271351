import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Operator } from '../../models/feed.model';

@Injectable({
    providedIn: 'root'
})
export class OperatorsService {
    http = inject(HttpClient);

    getAllOperators(): Observable<Operator[]> {
        return this.http.get<Operator[]>('assets/operators.json');
    }
}
