import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitLast',
    standalone: true
})
export class SplitLastPipe implements PipeTransform {
    transform(val: string, param: string): string {
        if (!val) {
            return '';
        }
        const splitAry = val.split(param);
        return splitAry[splitAry.length - 1];
    }
}
