@if (expressionFormControl().value === '') {
    <ui-button
        class="tw-ml-[15px]"
        text="CREATE AN EXPRESSION"
        [attr.data-testid]="'expression-btn'"
        (click)="fieldEditorDialog.open()" />
} @else {
    <mat-form-field
        appearance="outline"
        class="expression-input tw-w-full">
        <mat-label>{{ fieldName() }}</mat-label>
        <input
            matInput
            type="text"
            [attr.data-testid]="'expression-input'"
            [value]="expressionFormControl().value"
            readonly />
        <ui-svg-icon
            icon="edit"
            (click)="fieldEditorDialog.open()">
        </ui-svg-icon>
    </mat-form-field>
}
<ui-dialog
    #fieldEditorDialog="ui-dialog"
    [config]="{ headerText: 'EDIT ' + fieldName(), maxWidth: '80%', padding: 0 }">
    <ng-template ui-dialog-template>
        <expression-field-editor
            (closeDialog)="fieldEditorDialog.close()"
            [fieldName]="fieldName()"
            [expressionFormControl]="expressionFormControl()"
            [fieldId]="fieldId()">
        </expression-field-editor>
    </ng-template>
</ui-dialog>
