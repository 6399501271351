@if (selectedCreative) {
    <div
        #previewContainer
        class="tw-w-[300px] tw-aspect-square tw-h-full tw-flex tw-items-center tw-justify-center tw-pointer-events-none">
        <ui-creative-preview
            class="preview"
            [style]="{ width: newCreativeWidth + '%' }"
            [creative]="selectedCreative.id"
            [creativeset]="selectedCreative.creativeSetId"></ui-creative-preview>
    </div>
}
