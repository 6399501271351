<header-navigation>
  <ui-svg-icon
        slot="start"
        [icon]="'log'"
        [routerLink]="['./list']"></ui-svg-icon>
</header-navigation>
<div class="tw-flex tw-justify-center">
    <div class="tw-mt-19">
        @if (mainFeeds$ | async; as mainFeeds) {
            <ul class="feed-list">
                @for (mainFeed of mainFeeds; track mainFeed) {
                    <li class="feed-item">
                        <a
                            [routerLink]="['../feed-editor', mainFeed.id]"
                            class="feed-link"
                            >{{ mainFeed.name }}</a
                        >
                    </li>
                }
            </ul>
        }
    </div>
</div>
