import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isImage',
    standalone: true
})
export class IsImagePipe implements PipeTransform {
    transform(feedValue: string): boolean {
        if (feedValue) {
            return /\.(jpg|jpeg|png|svg)$/i.test(feedValue);
        }
        return false;
    }
}
