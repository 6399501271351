<div class="tw-sticky tw-top-0 tw-z-10">
    <ui-header [full]="true"
        ><div class="header-content">
            <ng-content select="[slot=start]"></ng-content>

            <div>
                @if (environment.stage === 'sandbox') {
                    <sandbox-pr-slots class="tw-mr-4"></sandbox-pr-slots>
                }
                <ng-content select="[slot=end]"></ng-content>
            </div></div
    ></ui-header>
</div>
