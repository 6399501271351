import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccountAccessService } from '../services/api/account-access.service';

export const userGuard = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<boolean> | Promise<boolean> | boolean => {
    const accountAccessService = inject(AccountAccessService);
    const router = inject(Router);

    const accountSlug = route.paramMap.get('account');
    const brandSlug = route.paramMap.get('brand');

    if (!accountSlug || !brandSlug) {
        return false;
    }

    accountAccessService.setAccountInfo({ accountSlug, brandSlug });

    return accountAccessService.getUser(accountSlug, brandSlug).pipe(
        tap(user => accountAccessService.setUser(user)),
        map(() => true),
        catchError(() => {
            router.navigateByUrl('');
            return of(false);
        })
    );
};
