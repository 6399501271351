import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UIModule } from '@bannerflow/ui';
import { ValidateFieldExpression } from 'src/app/validators/field-expression.validator';
import { PreviewService } from 'src/app/services/api/preview.service';
import { GenericFeedFieldForm } from 'src/app/services/ui/template-builder.service';

@Component({
    selector: 'field-editor',
    standalone: true,
    imports: [
        CommonModule, 
        UIModule,
        MatFormFieldModule,
        MatInputModule, 
        ReactiveFormsModule
    ],
    templateUrl: './field-editor.component.html',
    styleUrl: './field-editor.component.scss'
})
export class FieldEditorComponent {
    @Input() set formFieldControl(formGroup: FormGroup<GenericFeedFieldForm>) {
        this.fieldFormGroup = formGroup;
        if(formGroup.value.data)
            this.fieldInputFormControl.setValue(formGroup.value.data.toString())
        
        this.fieldInputFormControl.addAsyncValidators(
            ValidateFieldExpression.createValidator(this.previewService)
        );
    }  
    @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
    fieldFormGroup: FormGroup<GenericFeedFieldForm>;
    fieldInputFormControl = new FormControl('');
    private readonly previewService = inject(PreviewService);
    previewValue: string = 'dummyPreview';

    onClickConfirm() {
        const fieldEdited = this.fieldInputFormControl.value;
        if (fieldEdited == null)
            return;

        this.fieldFormGroup.controls.data.setValue([fieldEdited])
        this.closeDialog.emit();
    }
}
