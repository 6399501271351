import { inject, Injectable } from '@angular/core';
import { AccountAccessService } from './account-access.service';
import { filter, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private accountAccessService = inject(AccountAccessService);

 getBFCListUrl(): Observable<string> {
    return this.accountAccessService.accountInfo$.pipe(
      filter(accountInfo => !!accountInfo),
      map(accountInfo => `${environment.origins.bfc}/${accountInfo.accountSlug}/${accountInfo.brandSlug}/feeds`)
    );
  }
}
