import { inject, Injectable } from '@angular/core';
import { AccountAccessService } from './account-access.service';
import { filter, map, Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private accountAccessService = inject(AccountAccessService);
    private route = inject(Router);

    navigateToBFCListUrl(): Observable<void> {
        return this.accountAccessService.accountInfo$.pipe(
            filter(accountInfo => !!accountInfo), 
            map(accountInfo => ({
                url: `${environment.origins.bfc}/${accountInfo.accountSlug}/${accountInfo.brandSlug}/feeds`,
                navigationPath: `${accountInfo.accountSlug}/${accountInfo.brandSlug}/list`
            })),
            switchMap(({ url, navigationPath }) =>
                environment.stage === 'proxy'
                    ? this.accountAccessService.getAccountInfo().pipe(
                          map(() => {
                              this.route.navigate([navigationPath]);
                          })
                      )
                    : new Observable<void>(observer => {
                          window.location.href = url;
                          observer.next();
                          observer.complete();
                      })
            )
        );
    }
}
