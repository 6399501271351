<div class="tw-flex-col tw-justify-center tw-items-center tw-py-10 tw-px-16">
    <p class="tw-text-base">
        Create an expression for the
        <span class="tw-text-blue-600">{{ '"' + formGroup().controls.name.value + '"' }}</span> field yourself
    </p>
    <mat-form-field class="tw-w-full">
        <input
            matInput
            #editInput
            type="text"
            [formControl]="fieldInputFormControl" />
    </mat-form-field>
    @if (fieldInputFormControl.hasError('invalidSyntax')) {
        <mat-error> Invalid Syntax </mat-error>
    }
    <div class="tw-flex tw-justify-center tw-gap-5 tw-mb-10 tw-mt-10">
        <ui-button
            type="default"
            text="Cancel"
            [attr.data-testid]="'edit-cancel'"
            (click)="closeDialog.emit()">
        </ui-button>
        <ui-button
            id="interaction-edit-confirm"
            type="primary"
            text="Save"
            [disabled]="fieldInputFormControl.hasError('invalidSyntax')"
            [attr.data-testid]="'edit-confirm'"
            (click)="onClickConfirm()">
        </ui-button>
    </div>
</div>
