import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { BehaviorSubject, map, take, tap } from 'rxjs';
import { FeedFieldType } from 'src/app/models/feed.model';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { MainFeedFieldsService } from 'src/app/services/api/main-feed-fields.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { TemplateBuilderService } from 'src/app/services/ui/template-builder.service';
import { TemplateFieldsComponent } from './template-fields/template-fields.component';
import { FeedForm } from 'src/app/models/feed-form.model';

@Component({
    selector: 'select-fields',
    standalone: true,
    imports: [CommonModule, UIModule, TemplateFieldsComponent],
    templateUrl: './select-fields.component.html',
    styleUrls: ['./select-fields.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFieldsComponent {
    feedForm = input.required<FeedForm>();

    private mainfeedService = inject(MainFeedService);
    private mainFeedFieldsService = inject(MainFeedFieldsService);
    private feedSourceService = inject(FeedSourceService);
    private templateBuilderSerivce = inject(TemplateBuilderService);
    mainFeedId$ = this.mainfeedService.mainFeedId$;
    loading$ = new BehaviorSubject<boolean>(false);

    constructor() {
        effect(() => {
            this.mainFeedFieldsService
                .getSuggestedFields(this.feedForm().controls.template.controls.id.value)
                .subscribe(fields => {
                    fields.forEach(field => {
                        const fieldGroup = this.templateBuilderSerivce.buildFieldGroup(field);
                        this.feedForm().controls.blueprintFields.push(fieldGroup);
                    });
                });
        });
    }

    clickLoadDummyData(): void {
        const formArray = this.feedForm().controls.blueprintFields;
        const pathControlers = formArray.controls.filter(
            formgroup => formgroup.controls.$type.value === FeedFieldType.Path
        );

        this.feedSourceService
            .getFeedSourceFields()
            .pipe(
                take(1),
                map(feedNodes => feedNodes.filter(feedNode => !feedNode.isArray)),
                tap(feedNodes => {
                    pathControlers.forEach(control => {
                        let foundFeedNode = feedNodes.find(feedNode => control.controls.name.value == feedNode.name);

                        if (foundFeedNode == null) {
                            const randomIndex = Math.floor(Math.random() * feedNodes.length);
                            foundFeedNode = feedNodes[randomIndex];
                        }

                        control.controls.metaData.controls[FeedFieldType.Path].controls.path.setValue(
                            foundFeedNode.path
                        );
                    });
                })
            )
            .subscribe();
    }
}
