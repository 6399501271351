<cdk-tree
    [dataSource]="feedSchemaSource"
    [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <cdk-nested-tree-node
        *cdkTreeNodeDef="let node"
        class="tree-node tw-truncate">
        <div class="tw-flex tw-items-center tw-gap-1">
            @if (!node.isArray) {
                <ui-checkbox
                    color="primary"
                    (selectedChange)="toggleNode(node)"
                    [selected]="fieldSelection.isSelected(node)" />
            }

            <span class="tw-text-sm tw-font-semibold tw-text-ui-primary">{{ node.name }}</span>
        </div>
        <span
            [uiTooltip]="node.values"
            class="tw-text-xs tw-text-slate-700 tw-pl-8"
            >{{ node.values }}</span
        >
    </cdk-nested-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <cdk-nested-tree-node
        *cdkTreeNodeDef="let node; when: hasChild"
        class="tree-node tw-py-2">
        <div
            class="tw-flex tw-flex-row tw-items-center"
            [class.node-expand]="node.isArray">
            @if (!node.isArray) {
                <ui-checkbox
                    color="primary"
                    (selectedChange)="toggleNode(node)"
                    [selected]="fieldSelection.isSelected(node)" />
            }
            <span class="tw-text-sm tw-font-semibold tw-text-ui-primary">{{ node.name }}</span>
            <ui-svg-icon
                [icon]="treeControl.isExpanded(node) ? 'arrow-up' : 'arrow-down'"
                cdkTreeNodeToggle />
        </div>
        <div [class.node-tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
    </cdk-nested-tree-node>
</cdk-tree>
