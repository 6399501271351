import { CommonModule } from '@angular/common';
import { Component, effect, EventEmitter, inject, input, Input, output, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UIModule } from '@bannerflow/ui';
import { BaseFieldGroup } from 'src/app/models/feed-form.model';
import { FeedFieldType } from 'src/app/models/feed.model';
import { PreviewService } from 'src/app/services/api/preview.service';
import { ValidateFieldExpression } from 'src/app/validators/field-expression.validator';

@Component({
    selector: 'field-editor',
    standalone: true,
    imports: [CommonModule, UIModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
    templateUrl: './field-editor.component.html',
    styleUrl: './field-editor.component.scss'
})
export class FieldEditorComponent {
    formGroup = input.required<BaseFieldGroup>();
    closeDialog = output<void>();
    fieldInputFormControl = new FormControl('');
    private readonly previewService = inject(PreviewService);

    constructor() {
        effect(() => {
            if (this.formGroup()) {
                // Need to create a new form control to avoid triggering preview for the original formcontrol
                this.fieldInputFormControl.setValue(
                    this.formGroup().controls.metaData.controls[FeedFieldType.Path].controls.path.value
                );
                this.fieldInputFormControl.addAsyncValidators(
                    ValidateFieldExpression.createValidator(this.previewService)
                );
                this.fieldInputFormControl.updateValueAndValidity();
            }
        });
    }

    onClickConfirm() {
        if (this.fieldInputFormControl.valid && this.fieldInputFormControl.value) {
            this.formGroup().controls.metaData.controls[FeedFieldType.Path].controls.path.setValue(
                this.fieldInputFormControl.value
            );
            this.closeDialog.emit();
        }
    }
}
