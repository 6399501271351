import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TabSelectionService {
    private _selectTab$ = new BehaviorSubject<boolean>(false);
    selectTab$ = this._selectTab$.asObservable();

    selectTab(isTriggered: boolean): void {
        this._selectTab$.next(isTriggered);
    }
}
