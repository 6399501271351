import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { map, Observable } from 'rxjs';
import { MainFeedName } from '../models/feed.model';
import { MainFeedService } from '../services/api/main-feed.service';
import { HeaderNavigationComponent } from '../shared/header-navigation/header-navigation.component';

@Component({
    selector: 'templist',
    standalone: true,
    imports: [CommonModule, RouterModule, UIModule, HeaderNavigationComponent],
    templateUrl: './templist.component.html',
    styleUrls: ['./templist.component.scss']
})
export class TemplistComponent {
    private mainFeedService = inject(MainFeedService);

    mainFeeds$: Observable<MainFeedName[]> = this.mainFeedService
        .getAllMainFeeds()
        .pipe(map(val => val.map(mainFeed => ({ id: mainFeed.id!, name: mainFeed.name }))));
}
