@let integrations = (integrations$ | async) ?? [];

<div class="tw-p-10">
    <div class="tw-mb-4">
        <label
            for="catalogName"
            class="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
            >Catalog name <span class="tw-text-ui-alert">*</span></label
        >
        <ui-input (keyUpEvent)="catalogNameChanged($event)" />
    </div>

    <div class="tw-mb-4 tw-mt-10">
        <label class="tw-block tw-text-gray-700 tw-font-bold tw-mb-2">Connection to Meta</label>
        <p class="tw-text-gray-500 tw-text-xs tw-mb-10">
            @if (integrations.length > 0) {
                Required to check settings and accesses and to publish catalogs to Meta
            } @else {
                There are no available Meta integrations, please create one in the
                <a [href]="accountSettingsUrl$ | async">Account settings.</a>
            }
        </p>
    </div>

    <div class="tw-mb-6 tw-flex tw-flex-row tw-items-center tw-justify-between">
        <label
            class="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
            [ngClass]="{ 'tw-text-ui-grey-71': integrations.length === 0 }"
            >Integration <span class="tw-text-ui-alert">*</span></label
        >
        <ui-select
            class="tw-w-[360px] button-publish"
            [useTargetWidth]="true"
            width="100%"
            [placeholder]="'Select integration'"
            [selected]="(selectedIntegration$ | async) ?? null"
            (selectedChange)="integrationChanged($event)"
            [disabled]="integrations.length === 0">
            @for (integration of integrations; track $index) {
                <ui-option
                    [value]="integration"
                    [selected]="false"
                    >{{ integration.name }}</ui-option
                >
            }
        </ui-select>
    </div>

    <div class="tw-mb-4 tw-flex tw-flex-row tw-items-center tw-justify-between">
        <label
            class="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
            [ngClass]="{ 'tw-text-ui-grey-71': integrations.length === 0 }"
            >Business portfolio <span class="tw-text-ui-alert">*</span></label
        >
        <ui-select
            class="tw-w-[360px] button-publish"
            placeholder="Select business"
            width="100%"
            [useTargetWidth]="true"
            [selected]="(selectedBusiness$ | async) ?? null"
            (selectedChange)="businessChanged($event)"
            [disabled]="integrations.length === 0">
            @for (business of businesses$ | async; track $index) {
                <ui-option
                    [value]="business"
                    [selected]="false"
                    >{{ business.name }}</ui-option
                >
            }
        </ui-select>
    </div>

    <div class="tw-flex tw-justify-center tw-mt-10 tw-gap-4">
        <ui-button
            text="CANCEL"
            (click)="close.emit()" />
        <ui-button
            text="PUBLISH"
            type="primary"
            [loading]="isLoading"
            (click)="publishCatalog()"
            [disabled]="!(canPublish$ | async)" />
    </div>
</div>
