import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeedField, MainFeed } from '../../models/feed.model';

@Injectable({
    providedIn: 'root'
})
export class MainFeedService {
    private BASE_URL = `${environment.origins.feedsSupremeCommander}/api/MainFeeds`;
    private _mainFeedId$ = new BehaviorSubject<string>('');
    mainFeedId$ = this._mainFeedId$.asObservable();

    private http = inject(HttpClient);

    saveMainFeed(
        feedSourceUrl: string,
        fields: FeedField[],
        templateName: string,
        templateId: string
    ): Observable<string> {
        return this.http
            .post(
                this.BASE_URL,
                {
                    name: templateName,
                    sourceUrl: feedSourceUrl,
                    fields: fields,
                    templateId: templateId
                },
                {
                    responseType: 'text'
                }
            )
            .pipe(
                map(newMainFeedId => {
                    this._mainFeedId$.next(newMainFeedId);

                    return templateName;
                }),
                catchError(err => this.handleError(err, templateName))
            );
    }

    updateMainFeed(mainFeed: MainFeed): Observable<string> {
        const body = {
            name: mainFeed.name,
            sourceUrl: mainFeed.sourceUrl,
            fields: mainFeed.blueprint.fields,
            updateInterval: mainFeed.updateInterval
        };

        return this.http
            .put(`${this.BASE_URL}/${mainFeed.id}`, body, {
                responseType: 'text'
            })
            .pipe(catchError(err => this.handleError(err, mainFeed.name)));
    }

    getAllMainFeeds(): Observable<MainFeed[]> {
        return this.http.get<MainFeed[]>(this.BASE_URL).pipe(catchError(err => this.handleError(err, 'main feeds')));
    }

    getMainFeedById(id: string): Observable<Required<MainFeed>> {
        return this.http
            .get<Required<MainFeed>>(`${this.BASE_URL}/${id}`)
            .pipe(catchError(err => this.handleError(err, 'main feed')));
    }

    triggerUpdate(id: string) {
        return this.http
            .post(`${this.BASE_URL}/${id}/triggerUpdate`, {})
            .pipe(catchError(err => this.handleError(err, 'main feed')));
    }

    private handleError(error: HttpErrorResponse, templateName: string, id?: string) {
        if (error.status === 404) {
            // Handle 404 errors differently if needed
            return throwError(() => new Error('Endpoint not found. Please contact support.'));
        }
        // Return a more generic message for other errors
        return throwError(
            () =>
                new Error(
                    `An error occurred while ${id ? 'updating' : 'saving'} ${templateName}. Please try again later.`
                )
        );
    }
}
