import { ArrayDataSource } from '@angular/cdk/collections';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { UIDialogDirective, UIModule } from '@bannerflow/ui';
import { BehaviorSubject, map } from 'rxjs';
import { FeedFieldType } from 'src/app/models/feed.model';
import { SplitLastPipe } from 'src/app/pipes/split-last.pipe';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { TabSelectionService } from 'src/app/services/ui/tab-selection.service';
import { FieldEditorComponent } from 'src/app/views/stepper/select-fields/field-editor/field-editor.component';
import { FeedTreeFieldsComponent } from './feed-tree-fields/feed-tree-fields.component';

@Component({
    selector: 'field-select',
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatChipsModule,
        ReactiveFormsModule,
        MatIconModule,
        OverlayModule,
        MatButtonModule,
        UIModule,
        FieldEditorComponent,
        SplitLastPipe,
        FeedTreeFieldsComponent,
        MatInputModule
    ],
    templateUrl: './field-select.component.html',
    styleUrls: ['./field-select.component.scss']
})
export class FieldSelectComponent {
    @Input({ required: true }) fieldFormControl: FormControl;
    @Input({ required: true }) label: string;
    @Input() fieldType: FeedFieldType;
    @Input() id: string;
    @Output() chipClicked = new EventEmitter();
    @ViewChild('formField') formField!: ElementRef;
    private feedSourceService = inject(FeedSourceService);
    router = inject(Router);
    tabSelectionService = inject(TabSelectionService);
    feedSchemaSource$ = this.feedSourceService.getFeedSourceFields().pipe(map(nodes => new ArrayDataSource(nodes)));
    cdkOverlayWidth$ = new BehaviorSubject<number>(0);
    FeedFieldType = FeedFieldType;
    isOpen: boolean = false;
    inEditMode: boolean = false;
    private resizeObserver!: ResizeObserver;
    creativeText = 'The creative can be connected in the next step';

    ngOnInit(): void {
        if (this.router.url.includes('/edit')) {
            this.inEditMode = true;
        }
    }

    ngAfterViewInit(): void {
        this.setupResizeObserver();
    }

    removeField(keyword: string) {
        const index = this.fieldFormControl.value.indexOf(keyword);
        if (index >= 0) {
            const newValue = this.fieldFormControl.value.splice(index, 1);
            this.fieldFormControl.setValue(index === 0 ? [] : newValue);
        }
    }

    onChipClick(event: Event): void {
        event.stopPropagation();
        this.chipClicked.emit();
    }

    add(event: MatChipInputEvent): void {
        this.setChipValue(event.value);
        event.chipInput!.clear();
    }

    edit(value: string): void {
        this.setChipValue(value);
    }

    openSelect(event: Event) {
        event.stopPropagation();
        this.isOpen = !this.isOpen;
    }

    setChipValue(value: string): void {
        if (value == '') throw new Error('Empty chip value is not allowed');

        value = value.trim();

        this.fieldFormControl.setValue([value]);
    }

    private setupResizeObserver(): void {
        this.resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const width = entry.contentRect.width;
                this.cdkOverlayWidth$.next(width + 100);
            }
        });

        this.resizeObserver.observe(this.formField.nativeElement);
    }

    ngOnDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    selectCreativeTab(isTriggered: boolean): void {
        this.tabSelectionService.selectTab(isTriggered);
    }
}
