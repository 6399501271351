import { Component, computed, input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { UIModule } from '@bannerflow/ui';
import { BaseFieldGroup } from 'src/app/models/feed-form.model';
import { FeedFieldType } from 'src/app/models/feed.model';
import { DefaultCreativeCardComponent } from 'src/app/shared/default-creative-card/default-creative-card.component';

@Component({
    selector: 'connect-creative',
    standalone: true,
    imports: [UIModule, DefaultCreativeCardComponent],
    templateUrl: './connect-creative.component.html',
    styleUrl: './connect-creative.component.scss'
})
export class ConnectCreativeComponent {
    blueprintFields = input.required<FormArray<BaseFieldGroup<FeedFieldType>>>();

    // TODO: Temporary solution, in the future we will have multiple creative fields
    // and we should be able to use creativeFields$ directly to render
    // single/multiple "DefaultCreativeCardComponent" or multiple "ConditionalCreativeCardComponent"
    singleCreativeField = computed(() => {
        return this.blueprintFields().controls.find(field => field.value.$type === FeedFieldType.Creative)!;
    });
}
