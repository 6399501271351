@let creativeField = singleCreativeField$ | async;

@if (creativeField) {
    <default-creative-card
        [creativeField]="creativeField"
        (dynamicPropertiesChanged)="setCreativeDynamicFields($event)" />
}

<bottom-navigation>
    <div class="tw-flex tw-gap-6 tw-pr-5 tw-grow-1 tw-justify-end">
        <p class="tw-text-ui-primary">
            <ui-svg-icon
                [icon]="'info-l'"
                class="info" />This step can be skipped and completed later
        </p>
        <ui-button
            type="primary"
            text="CUSTOMIZE FEED"
            [loading]="loading"
            (click)="saveCreativeCards()"></ui-button>
    </div>
</bottom-navigation>
