import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FeedField, PreviewData } from '../../models/feed.model';
import { FilterGroup, FilterGroups } from '../../models/filters.model';
import { catchError, map, Observable, switchMap, take } from 'rxjs';
import { FeedSourceService } from './feed-source.service';

interface PreviewDataRequest {
    id?: string;
    source: string;
    fields: FeedField[];
    filters?: FilterGroup[];
}

@Injectable({
    providedIn: 'root'
})
export class PreviewService {
    private http = inject(HttpClient);
    private feedSourceService = inject(FeedSourceService);

    getPreviewData(request: PreviewDataRequest): Observable<PreviewData> {
        return this.http.post<PreviewData>(`${environment.origins.feedsFearsomeProcessor}/Preview`, {
            ...request
        });
    }

    validateFieldExpression(fields: FeedField[]): Observable<boolean> {
        return this.feedSourceService.feedSourceUrl$.pipe(
            take(1),
            switchMap(url => {
                const request = {
                    source: url,
                    fields: fields,
                    filters: []
                }
                return this.getPreviewData(request).pipe(
                    map(previewData => {
                        if(previewData == undefined)
                            return false;
        
                        return true;
                    })
                ).pipe(
                    catchError(async () => false)
                );
            })
        )
    }
}
