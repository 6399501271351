import { animate, state, style, transition, trigger } from '@angular/animations';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UIModule } from '@bannerflow/ui';
import { take } from 'rxjs';
import { FilterGroupForm } from 'src/app/models/filters.model';
import { NavigationService } from 'src/app/services/api/navigation.service';
import { DraggableBottomSheetService } from 'src/app/services/ui/draggable-bottom-sheet.service';
import { FiltersComponent } from '../../../shared/filters/filters.component';

@Component({
    selector: 'customise-feed',
    standalone: true,
    imports: [
        CommonModule,
        FiltersComponent,
        UIModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        ClipboardModule,
        MatButtonModule
    ],
    templateUrl: './customise-feed.component.html',
    styleUrls: ['./customise-feed.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state(
                'void',
                style({
                    opacity: 0
                })
            ),
            transition('void <=> *', animate('500ms'))
        ])
    ]
})
export class CustomiseFeedComponent {
    filterGroups = input.required<FormArray<FilterGroupForm>>();
    isSaved = input.required<boolean>();
    draggableBottomSheetService = inject(DraggableBottomSheetService);
    navigationService = inject(NavigationService);

    goToBFCListUrl(): void {
        this.navigationService.navigateToBFCListUrl().pipe(take(1)).subscribe();
    }
}
