import { CommonModule } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { UIModule } from '@bannerflow/ui';
import { GenericFeedFieldForm } from 'src/app/services/ui/template-builder.service';
import { EditableFieldComponent } from 'src/app/shared/editable-field/editable-field.component';
import { FeedTreeFieldsComponent } from 'src/app/shared/field-select/feed-tree-fields/feed-tree-fields.component';
import { FieldSelectComponent } from 'src/app/shared/field-select/field-select.component';
import { FieldEditorComponent } from '../field-editor/field-editor.component';
import { SelectTypeComponent } from '../select-type/select-type.component';

@Component({
    selector: 'dynamic-form',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        FieldSelectComponent,
        FeedTreeFieldsComponent,
        EditableFieldComponent,
        UIModule,
        SelectTypeComponent,
        FieldEditorComponent
    ],
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent {
    @ContentChild('header', { static: false }) headerTemplateRef: TemplateRef<any>;
    @Input() formArray: FormArray<FormGroup<GenericFeedFieldForm>>;
    kebabMenuOptions = ['Delete', 'Rename'];

    onKebabMenuOptionClicked(option: string, index: number): void {
        if (option === 'delete') {
            this.deleteField(index);
        }
    }

    deleteField(index: number): void {
        this.formArray.removeAt(index);
    }
}
