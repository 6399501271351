<div class="tw-mb-10">
    @if (pageInfo$ | async; as pageInfo) {
        <div class="tw-flex tw-justify-end tw-items-center tw-gap-1 tw-relative tw-top-11">
            <ui-button
                (click)="previous(pageInfo.currentPage)"
                ui-theme="minimal"
                [svgIcon]="'arrow-left'"
                [disabled]="pageInfo.currentPage === 1"></ui-button>
            <p>{{ pageInfo.currentPage }} / {{ pageInfo.totalCards }}</p>
            <ui-button
                (click)="next(pageInfo.currentPage)"
                ui-theme="minimal"
                [svgIcon]="'arrow-right'"
                [disabled]="pageInfo.currentPage === pageInfo.totalCards"></ui-button>
        </div>
        <div class="grid-3-col tw-mb-5 tw-text-ui-midnight">
            <h3>Template fields</h3>
            <h3>Values from your source</h3>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-8">
            @for (item of pageInfo.activeCard | keyvalue: returnZero; track item; let lastItem = $last) {
                <div class="grid-3-col text tw-pb-1">
                    <div>{{ item.key }}</div>
                    <preview-value [previewValue]="item.value !== null ? item.value.toString() : ''"></preview-value>
                </div>
                @if (!lastItem) {
                    <div class="tw-w-full tw-h-[1px] tw-bg-ui-grey-84"></div>
                }
            }
        </div>
    }
</div>
