import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { TemplateOptionalFieldsResponse } from '../../models/templates.model';

@Injectable({
    providedIn: 'root'
})
export class SocialFieldsService {
    private http = inject(HttpClient);

    getSocialFields(id: string): Observable<TemplateOptionalFieldsResponse> {
        return this.http
            .get<TemplateOptionalFieldsResponse>(
                `${env.origins.feedsSupremeCommander}/api/Templates/${id}/optionalfields`
            )
            .pipe(
                catchError(err => {
                    console.error('Error fetching facebook fields: ', err);
                    return of({ optionalFields: [] });
                })
            );
    }
}
