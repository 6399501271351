import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { DynamicFieldType, FeedField, FeedFieldType } from 'src/app/models/feed.model';

export interface GenericFeedFieldForm {
    type: FormControl<FeedFieldType>;
    name: FormControl<string>;
    id: FormControl<string>;
    data: FormControl<string[]>; // Can either be a Path or a Static Text
    dynamicContentType?: FormControl<DynamicFieldType>;
    removeable: FormControl<boolean>;
}

export type GenericFeedFieldFormGroup = FormGroup<{ fields: FormArray<FormGroup<GenericFeedFieldForm>> }>;

@Injectable({
    providedIn: 'root'
})
export class TemplateBuilderService {
    fb = inject(NonNullableFormBuilder);

    buildForm(fields: FeedField[]): GenericFeedFieldFormGroup {
        const group = this.fb.group({
            fields: this.buildFieldsArray(fields)
        });

        return group;
    }

    private buildFieldsArray(fields: FeedField[]): FormArray<FormGroup<GenericFeedFieldForm>> {
        return this.fb.array(fields.map(field => this.buildFieldGroup(field)));
    }

    buildFieldGroup(field: FeedField): FormGroup<GenericFeedFieldForm> {
        let dataValue = '';

        if (field.$type === FeedFieldType.StaticText) {
            dataValue = field.text;
        } else if (field.$type === FeedFieldType.Path) {
            dataValue = field.path !== undefined && field.path !== '' ? field.path : '';
        } else if (field.$type === FeedFieldType.Creative) {
            dataValue = field.creativeId;
        }
        
        const baseGroup = this.fb.group<GenericFeedFieldForm>({
            type: this.fb.control(field.$type),
            name: this.fb.control(field.name),
            id: this.fb.control(field.id),
            data: this.fb.control(dataValue !== '' ? [dataValue] : [], {
                validators: field.required && field.$type !== FeedFieldType.Creative ? Validators.required : null
            }),
            removeable: this.fb.control(field.required ? false : true)
        });

      if (field.dynamicContentType) {
            baseGroup.addControl('dynamicContentType', this.fb.control(field.dynamicContentType));
        }

        return baseGroup;
    }
}
